import Form0 from "components/pages/finanCheck/form0";
import Form1 from "components/pages/finanCheck/form1";
import Form2 from "components/pages/finanCheck/form2";
import Result from "components/pages/finanCheck/result";
import {IPageRoutes} from "routes";

const pageRoutes: IPageRoutes = {
  default: {
    component: Form0,
    type: "form",
  },
  form0: {
    component: Form0,
    type: "form",
  },
  form1: {
    component: Form1,
    type: "form",
  },
  form2: {
    component: Form2,
    type: "form",
  },
  result: {
    component: Result,
    type: "result",
  },
};

export default pageRoutes;
