import {Box, makeStyles, Theme, Typography} from "@material-ui/core";
import Button from "components/common/Button";
import FormContainer from "components/common/FormContainer";
import GenderInput from "components/common/GenderInput";
import NumberInput from "components/common/NumberInput";
import SelectInput from "components/common/SelectInput";
import {dataContext, retireplanContext} from "context/context";
import {Form, Formik, useField, useFormikContext} from "formik";
import useDialog from "hooks/useDialog";
import useRouter from "hooks/useRouter";
import {IRetirePlan} from "interface/interface";
import React, {useContext} from "react";
import RPQ from "../RPQ";
import AgeInput from "./AgeInput";
import FormItem from "./FormItem";
import SliderInput from "./SliderInput";

interface IFormField {
  gender: string;
  age: number;
  retriment_age: number;
  death_age: number;
  outcome: number;
  inflationrate: number;
  retryment_rq: number;
  need_insurance: number;
  risk_accepted: number;
}
const useStyles = makeStyles((theme: Theme) => ({
  RPQ: {
    fontSize: "1.25rem",
    width: "200px",
    flexGrow: 1,
    margin: "auto",
    maxWidth: "350px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      maxWidth: "300px",
    },
  },
  ageInput: {
    margin: "10px 0 10px 0",
    width: "100%",
    display: "flex",
  },
  button: {
    width: "250px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      justifyContent: "center",
    },
  },
}));

const risk_options = [
  {
    value: "0",
    text: "00",
  },
  {
    value: "1",
    text: "01",
  },
  {
    value: "2",
    text: "02",
  },
  {
    value: "3",
    text: "03",
  },
];

function RetirementPlanForm() {
  const styles = useStyles();

  const {values}: {values: IRetirePlan} = useFormikContext();
  const [, , page] = useField("page");

  const {Dialog, openDialog} = useDialog(RPQ);

  const Questions = (
    <Box display="flex" flexWrap="wrap">
      <FormItem
        label="เพศ"
        width="300px"
        input={<GenderInput name="gender" />}
      />
      <div className={styles.ageInput}>
        <AgeInput label="อายุปัจจุบัน" name="age" start />
        <AgeInput label="อายุเกษียณ" name="retriment_age" />
        <AgeInput label="สิ้นอายุขัย " name="death_age" end />
      </div>
      <FormItem
        label="รายจ่ายปัจจุบัน"
        width="450px"
        input={<NumberInput name="outcome" unit="บาท/เดือน" />}
      />
      <FormItem
        label="อัตราเงินเฟ้อ"
        width="250px"
        input={<NumberInput name="inflationrate" type="float" unit="%" />}
      />
      <FormItem
        label="เงินบำนาญขั้นต่ำที่ควรมี"
        width="500px"
        input={
          <Box
            display="flex"
            width="100%"
            textAlign="center"
            padding="0 10px 0 10px"
          >
            <Typography style={{flexGrow: 1}}>
              {(
                values.outcome *
                0.7 *
                Math.pow(
                  1 + values.inflationrate / 100,
                  values.retriment_age - values.age
                )
              ).toLocaleString()}
            </Typography>
            <Typography>บาท/เดือน</Typography>
          </Box>
        }
      />
      <FormItem
        label="เงินบำนาญที่ต้องการมี"
        width="500px"
        input={<NumberInput name="retryment_rq" unit="บาท/เดือน" />}
      />
      <FormItem
        label="ความเสี่ยงจากการลงทุนที่รับได้"
        width="600px"
        input={<SelectInput name="risk_accepted" options={risk_options} />}
      />
      <Button className={styles.RPQ} onClick={openDialog}>
        แบบประเมินความเสี่ยงที่ยอมรับได้
      </Button>
      <SliderInput name="need_insurance" />
    </Box>
  );

  return (
    <Box marginTop="20px">
      <Typography variant="h1" color="primary">
        วางแผนเกษียณ
      </Typography>

      <FormContainer name="กรอกข้อมูลสำคัญ">{Questions}</FormContainer>

      <div className={styles.buttonContainer}>
        <Typography variant="h3" style={{margin: "10px"}}>
          ทางเลือกในการออม
        </Typography>
        <Button
          type="submit"
          className={styles.button}
          onClick={() => {
            page.setValue("insurance");
          }}
        >
          ประกันชีวิต
        </Button>
        <Button
          type="submit"
          className={styles.button}
          onClick={() => {
            page.setValue("mutualFund");
          }}
        >
          กองทุนรวม
        </Button>
      </div>
      <Dialog />
    </Box>
  );
}

const Wrapper = () => {
  const {setPage} = useRouter();
  const {data, setData} = useContext(dataContext);
  const {temp, setTemp} = useContext(retireplanContext);
  const {
    gender,
    age,
    retriment_age,
    death_age,
    outcome,
    inflationrate,
    retryment_rq,
    need_insurance,
  } = temp;

  const initialValues: IFormField = {
    gender,
    age,
    retriment_age,
    death_age,
    outcome,
    inflationrate,
    retryment_rq,
    need_insurance,
    risk_accepted:
      !temp.risk_accepted || temp.risk_accepted == -1 ? 0 : temp.risk_accepted,
  };

  const handleClick = (values: IFormField & {page: string}) => {
    let need_insurance_money = temp.need_insurance_money;
    let need_mutualfund_money = temp.need_mutualfund_money;
    if (
      need_insurance_money == -1 ||
      temp.need_insurance != values.need_insurance ||
      temp.retryment_rq != values.retryment_rq
    ) {
      need_insurance_money =
        (values.need_insurance * values.retryment_rq) / 100;
      need_mutualfund_money =
        ((100 - values.need_insurance) * values.retryment_rq) / 100;
    }
    const updateTemp = {
      gender: values.gender,
      age: values.age,
      retriment_age: values.retriment_age,
      death_age: values.death_age,
      outcome: values.outcome,
      inflationrate: values.inflationrate,
      retryment_rq: values.retryment_rq,
      need_insurance: values.need_insurance,
      need_insurance_money,
      need_mutualfund: 100 - values.need_insurance,
      need_mutualfund_money,
      risk_accepted: values.risk_accepted,
      risk_data: {
        risk_form_id: 0,
        risk_score: 0,
        risk_assessed: 0,
      },
    };
    setTemp({...temp, ...updateTemp});
    setData({...data, retirePlan: {...temp, ...updateTemp}});
    setPage(values.page);
  };

  return (
    <Formik initialValues={{...initialValues, page: ""}} onSubmit={handleClick}>
      <Form>
        <RetirementPlanForm />
      </Form>
    </Formik>
  );
};

export default Wrapper;
