import {Grid, Hidden, makeStyles, Theme, Typography} from "@material-ui/core";
import React, {useContext} from "react";
import {financheckContext} from "context/context";
import NumberInput from "components/common/NumberInput";
import TableTypo from "components/pages/finanCheck/component/TableTypo";
const useStyles = makeStyles((theme: Theme) => ({
  column: {
    minWidth: "100%",
    textAlign: "center",
    alignItems: "center",
    minHeight: "40px",
    height: "100%",
    borderRadius: "10px",
    display: "flex",
    alignContent: "center",
    overflow: "hidden",
  },
  backgroundWhite: {
    background: "white",
  },
}));
function FinanCheckItem(props: {
  name: string;
  id: number;
  value: number;
  type: number;
}) {
  const styles = useStyles();
  const {name, id, value, type} = props;
  const {temp, setTemp} = useContext(financheckContext);
  const {plans} = temp;
  const handleChange = (value: number) => {
    if (type == 1) {
      plans[id].useMoney = value;
    } else {
      plans[id].prepareMoney = value;
    }
    setTemp({...temp, plans});
  };
  return (
    <>
      <Grid container spacing={1} style={{marginBottom: "5px"}}>
        <TableTypo xs={6}>{name}</TableTypo>
        <Grid item md={5} xs={6}>
          <NumberInput
            onChange={handleChange}
            type="int"
            value={
              (plans[id] &&
                (type == 1 ? plans[id].useMoney : plans[id].prepareMoney)) ||
              value ||
              0
            }
          />
        </Grid>
        <Hidden smDown>
          <Grid item xs={1}>
            <Typography className={styles.column}>บาท</Typography>
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
}
export default FinanCheckItem;
