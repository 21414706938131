import {makeStyles, Theme, Typography} from "@material-ui/core";
import NumberInput from "components/common/NumberInput";
import color from "config/color";
import React from "react";
interface IProps {
  start?: boolean;
  end?: boolean;
  label: string;
  name: string;
}
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  lineContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  line: {
    flexGrow: 1,
    height: "3px",
  },
  dot: {
    width: "20px",
    height: "20px",
    borderRadius: "10px",
    backgroundColor: color.grey[300],
    [theme.breakpoints.down("sm")]: {
      width: "15px",
      height: "15px",
    },
  },
  numberInput: {
    width: "120px",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90px",
    },
  },
}));

function AgeInput(props: IProps) {
  const {start, end, label, name} = props;
  const styles = useStyles();
  const Line = (props: {style: React.CSSProperties}) => {
    return <div className={styles.line} style={props.style}></div>;
  };
  const Dot = () => {
    return <div className={styles.dot}></div>;
  };

  return (
    <div className={styles.container}>
      <div className={styles.lineContainer}>
        <Line style={{backgroundColor: start ? "" : color.grey[300]}} />
        <Dot />
        <Line style={{backgroundColor: end ? "" : color.grey[300]}} />
      </div>
      <Typography variant="body1" style={{margin: "10px 0 10px 0"}}>
        {label}
      </Typography>
      <NumberInput name={name} unit="ปี" className={styles.numberInput} />
    </div>
  );
}

export default AgeInput;
