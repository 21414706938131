import {Box, makeStyles, Theme, Typography} from "@material-ui/core";
import Button from "components/common/Button";
import Table from "components/common/table";
import color from "config/color";
import React from "react";
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: "900px",
    margin: "auto",
    marginTop: "20px",
    padding: "10px",
    [theme.breakpoints.down("md")]: {
      padding: "0px",
    },
  },
  content: {
    marginTop: "20px",
  },
  roomCost: {
    position: "absolute",
    textAlign: "center",
    width: "100%",
    top: 0,
  },
  seriousIllnessCost: {
    position: "absolute",
    left: "5%",
    bottom: "15%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      left: "0",
    },
  },
  packageCost: {
    position: "absolute",
    right: "5%",
    bottom: "15%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      right: "0",
    },
  },
}));

interface PlanList {
  scale: {
    room: number;
    health: number;
    disease: number;
  };
  data: {
    plan: string;
    room: number;
    health: number;
    disease: number;
    premium: number;
    remark?: string;
  }[];
}
const label = {
  room: "ค่าห้องพัก",
  health: "ค่ารักษาแบบเหมาจ่าย",
  disease: "ความคุ้มครองโรคร้ายแรง",
  premium: "เบี้ยปีแรก",
};

interface Props {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  planList: PlanList;
}

function Result1(props: Props) {
  const styles = useStyles();
  const {planList, setStep} = props;
  return (
    <div className={styles.container}>
      <Typography variant="h1" color="primary">
        ประกันสุขภาพระยะยาว
      </Typography>
      <div className={styles.content}>
        <Table
          type="column"
          tableKey={["plan", "room", "health", "disease", "premium"]}
          label={label}
          data={planList.data}
          headStyle={{backgroundColor: color.blue[300], color: "white"}}
          rowHeadStyle={{
            backgroundColor: color.blue[400],
            color: "white",
            borderRadius: "20px 0 0 20px",
          }}
        />
      </div>
      <Box display="flex" justifyContent="flex-end" marginTop="20px">
        <Typography variant="body1">{planList.data[0].remark}</Typography>
        <Typography variant="body1">{planList.data[1].remark}</Typography>
      </Box>
      <Button
        style={{
          float: "right",
          marginTop: "15px",
        }}
        onClick={() => {
          setStep(2);
        }}
      >
        แผนภูมิ
      </Button>
    </div>
  );
}
export default Result1;
