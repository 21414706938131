import {
  Box,
  Divider,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import axios from "axios";
import Button from "components/common/Button";
import color from "config/color";
import {retireplanContext} from "context/context";
import useRouter from "hooks/useRouter";
import React, {useContext, useEffect, useState} from "react";
import Insurance from "./Insurance";
import MutualFund from "./MutualFund";

const useStyles = makeStyles((theme: Theme) => ({
  summaryValue: {
    display: "flex",
    backgroundColor: "white",
    borderRadius: "10px",
    width: "70%",
    padding: "10px",
    margin: "auto",
  },
  summaryContainer: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: color.grey[100],
    borderRadius: "20px",
    padding: "0 10px 10px 10px",
    marginTop: "20px",
  },
  savingPlanTitle: {
    backgroundColor: color.blue[400],
    color: "white",
    padding: "10px",
    borderRadius: "10px",
    textAlign: "center",
  },
}));
function Result() {
  const {setPage} = useRouter();
  const styles = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const [fetchData, setFetchData] = useState({
    need_savings: 53333,
    retryment_rq: 30000,
  });
  const {temp} = useContext(retireplanContext);
  const getResult = async () => {
    let device_id = localStorage.getItem("device_id");
    const url = "https://fhc.insuretechthailand.com/ins/retirement/summary";
    if (!!temp) {
      const {
        age,
        gender,
        retriment_age,
        death_age,
        outcome,
        inflationrate,
        retryment_rq,
        risk_data,
        need_insurance,
        risk_accepted,
        insure_plan,
        fund_port,
      } = temp;
      const info = {
        device_id: device_id, // device_id ของ local
        cal_type: "calculate", // function ที่เรียกใช้   ins_recommend , fund_recommend
        sex: gender, //เพศ
        age, //อายุปัจจุบัน
        retriment_age, // defualt 60
        death_age, // defualt 85
        outcome, //รายจ่ายปัจจุบัน
        inflationrate, //อัตราเงินเฟ้อ
        retryment_sh:
          outcome * 0.7 * (1 + inflationrate) ** (retriment_age - age), //เงินบำนาญขั้นต่ำที่ควรมี   =  outcome * 0.7 ((1+(inflation))**(retriment_age - Age))
        retryment_rq, //เงินบำนาญที่ต้องการรมี  ระบุเอง
        risk_form_id: risk_data.risk_form_id, //not edit  default จาก response.risk_form_id
        risk_assessed: risk_data.risk_assessed, //not edit  default จาก response.risk_assessed
        risk_accepted, //ความเสี่ยงจากการลงทุนที่รับได้ default จาก response.risk_assessed   แต่เลือกเปลี่ยนเองได้
        need_insurance: need_insurance, //  0 - 100 need_insurance + need_mutualfund =  100
        need_mutualfund: 100 - need_insurance, //  0 - 100 need_insurance + need_mutualfund =  100
        select_plan: insure_plan,
        fund_port,
        time: Date.now(),
        calculateAT: new Date().toLocaleString(),
      };
      const response = await axios.post(url, info);
      if (!!response.data.data) {
        setFetchData(response.data.data);
      }
    }
  };

  useEffect(() => {
    getResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Summary
  const SummaryItem = (props: {text: string; value: string | number}) => (
    <div style={{flexGrow: 1}}>
      <Typography variant="h3" style={{textAlign: "center", margin: "10px"}}>
        {props.text}
      </Typography>
      <div className={styles.summaryValue}>
        <Typography variant="body1" style={{flexGrow: 1, textAlign: "center"}}>
          {props.value.toLocaleString()}
        </Typography>
        <Typography variant="body1">บาท</Typography>
      </div>
    </div>
  );
  const Summary = () => (
    <div className={styles.summaryContainer}>
      <SummaryItem
        text="คุณต้องออมเงินเดือนละ"
        value={fetchData.need_savings}
      />
      <SummaryItem
        text="เพื่อให้มีเงินใช้หลังเกษียณ"
        value={fetchData.retryment_rq}
      />
    </div>
  );

  // Savings plan
  const SavingsPlan = () => (
    <div>
      <div style={{display: "flex", justifyContent: "center", margin: "20px"}}>
        <Typography variant="h2" className={styles.savingPlanTitle}>
          คำแนะนำการวางแผนออมเงิน
        </Typography>
      </div>
      <div style={{display: "flex", flexDirection: isMd ? "column" : "row"}}>
        <Insurance />
        <Divider
          orientation={isMd ? "horizontal" : "vertical"}
          flexItem={!isMd}
        />
        <MutualFund />
      </div>
    </div>
  );

  return (
    <Box marginTop="20px">
      <Typography variant="h1" color="primary">
        วางแผนเกษียณ
      </Typography>
      <Summary />
      <SavingsPlan />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexWrap: "wrap",
        }}
      >
        <Button onClick={() => setPage("insurance")}>ประกันชีวิต</Button>
        <Button onClick={() => setPage("mutualFund")}>กองทุนรวม</Button>
      </div>
    </Box>
  );
}

export default Result;
