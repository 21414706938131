import {
  CircularProgress,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React, {useContext, useEffect, useState} from "react";
import TypeInput from "./component/TypeInput";
import {dataContext} from "context/context";
import axios from "axios";
import {v4} from "uuid";
import Table from "components/common/table";
import color from "config/color";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: "900px",
    margin: "auto",
    marginTop: "20px",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  summaryContainer: {
    marginTop: "20px",
    width: "60%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  sum: {
    backgroundColor: color.grey[100],
    display: "flex",
    minHeight: "60px",
    alignItems: "center",
    textAlign: "center",
    borderRadius: "10px",
    marginTop: "10px",
    marginBottom: "20px",
  },
  tableHead: {
    backgroundColor: color.blue[400],
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    minHeight: "60px",
    color: "white",
    border: "1px solid white",
  },
}));

function SumAssuredResult() {
  const styles = useStyles();
  const [type, setType] = useState(false);
  const [planList, setPlanList] = useState([]);
  const {data} = useContext(dataContext);
  const sumAssuredData = data.sumAssured!;

  const rate = sumAssuredData.inflaRate / 100;
  let needbase = sumAssuredData.outcome * 12;
  let potentialbase = sumAssuredData.income * 12;
  for (let i = 1; i < sumAssuredData.adaptPeriod; i++) {
    needbase += (sumAssuredData.outcome * 12) / Math.pow(1 - rate, i);
  }
  needbase = needbase + sumAssuredData.debt;
  needbase = Math.round(needbase / 1000) * 1000;
  for (let i = sumAssuredData.age; i < 60; i++) {
    potentialbase =
      potentialbase +
      Math.trunc(potentialbase * rate) +
      sumAssuredData.income * 12;
  }
  potentialbase = Math.round(potentialbase / 1000) * 1000;
  let device_id = localStorage.getItem("device_id");
  if (device_id === null) {
    device_id = v4();
    localStorage.setItem("device_id", device_id);
  }
  const getResult = async () => {
    const url = "https://fhc.insuretechthailand.com/ins/sumassured/cal";
    const info = {
      cal_type: "sumassured",
      device_id,
      age: sumAssuredData.age,
      sex: sumAssuredData.gender,
      income: 40000,
      outcome: 30000,
      debt: 1000000,
      adoption_period: 5,
      inflationrate: 0.02,
      Base: [needbase, potentialbase],
      time: Date.now(),
      calculateAT: new Date().toLocaleString(),
    };
    const response = await axios.post(url, info);
    setPlanList(response.data.data);
  };
  useEffect(() => {
    getResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const planData = planList.map((planData: any, inx) => {
    const mult = sumAssuredData.gender ? planData.female : planData.male;
    const base = type ? potentialbase : needbase;
    return {
      plan: planData.plan,
      insurancePremiumPerYear: (mult * base) / 1000,
      year: planData.pay_period,
      insurancePremium: ((mult * base) / 1000) * planData.pay_period,
    };
  });

  const minValue = [
    Math.min(...planData.map((d) => d.insurancePremiumPerYear)),
    Math.min(...planData.map((d) => d.year)),
    Math.min(...planData.map((d) => d.insurancePremium)),
  ];

  const highlight = [];
  for (const data of planData) {
    const rowHighlight: string[] = [];
    if (data.insurancePremiumPerYear == minValue[0]) {
      rowHighlight.push("insurancePremiumPerYear");
    }
    if (data.year == minValue[1]) {
      rowHighlight.push("year");
    }
    if (data.insurancePremium == minValue[2]) {
      rowHighlight.push("insurancePremium");
    }
    highlight.push(rowHighlight);
  }

  if (planList.length == 0) {
    return <CircularProgress style={{marginLeft: "45%", marginTop: "40vh"}} />;
  }

  return (
    <>
      <div className={styles.container}>
        <Typography variant="h1" color="primary">
          ทุนประกันชีวิตที่เหมาะสม
        </Typography>
        <div className={styles.summaryContainer}>
          <TypeInput type={type} setType={setType} />
          <div className={styles.sum}>
            <Typography variant="h2" style={{flexGrow: 1}}>
              {(type ? potentialbase : needbase).toLocaleString()} บาท
            </Typography>
          </div>
        </div>
        <Table
          tableKey={[
            "plan",
            "insurancePremiumPerYear",
            "year",
            "insurancePremium",
          ]}
          data={planData}
          label={{
            plan: "",
            insurancePremiumPerYear: "เบี้ยประกันภัย (ต่อปี)",
            year: "จำนวนปีที่ต้องชำระเงิน",
            insurancePremium: "เบี้ยประกันภัยรวม",
          }}
          highlight={highlight}
          headStyle={{backgroundColor: color.blue[400], color: "white"}}
          rowHeadStyle={{
            backgroundColor: color.blue[300],
            borderRadius: "20px 0 0 20px",
          }}
        />
      </div>
    </>
  );
}
export default SumAssuredResult;
