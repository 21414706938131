import {Grid, Typography} from "@material-ui/core";
import NumberInput from "components/common/NumberInput";
import SelectInput from "components/common/SelectInput";
import React from "react";
import {TiMinus, TiPlus} from "react-icons/ti";
import getTypeColor from "../utils/getTypeColor";

interface IProps {
  name: string;
  type: string;
  percent: number;
  onNameChange: (value: string) => void;
  onPercentChange: (percent: number) => void;
  options: {value: string; text: string}[];
}
function Fund(props: IProps) {
  const {name, type, percent, onNameChange, onPercentChange, options} = props;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <SelectInput value={name} onChange={onNameChange} options={options} />
      </Grid>

      <Grid item xs={6} md={3}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            borderRadius: "10px",
            backgroundColor: getTypeColor(type),
          }}
        >
          <Typography variant="body2" style={{textAlign: "center"}}>
            {type}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{cursor: "pointer", flexGrow: 1}}>
            <TiPlus
              style={{cursor: "pointer", flexGrow: 1}}
              size="15px"
              onClick={() => {
                onPercentChange(percent + 1);
              }}
            />
          </div>
          <NumberInput
            style={{margin: "0 5px 0 5px"}}
            value={percent}
            unit="%"
            onChange={onPercentChange}
          />
          <div style={{cursor: "pointer", flexGrow: 1}}>
            <TiMinus
              onClick={() => {
                onPercentChange(percent - 1);
              }}
              size="15px"
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default Fund;
