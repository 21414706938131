import {makeStyles, Select} from "@material-ui/core";
import withFormik from "components/hoc/withFormik";
import {FieldInputProps} from "formik";
import React, {useCallback} from "react";

interface Props {
  value: string;
  onChange: (value: string) => void;
  field?: FieldInputProps<any>;

  options: {value: string; text: string}[];
}

const SelectInput = (props: Props) => {
  const styles = useStyles();
  const {options, value, onChange, field} = props;

  const handleClick = useCallback(
    (event: React.ChangeEvent<any>) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  return (
    <Select
      native
      disableUnderline
      className={styles.input}
      {...field}
      value={value}
      onChange={handleClick}
    >
      {options.map((option) => (
        <option
          key={option.value}
          value={option.value}
          style={{color: "black", background: "white"}}
        >
          {option.text}
        </option>
      ))}
    </Select>
  );
};
export default withFormik(SelectInput);

const useStyles = makeStyles(() => ({
  input: {
    backgroundColor: "white",
    width: "100%",
    height: "40px",
    padding: "10px",
    borderRadius: "10px",
    "& select": {
      "&:focus": {
        backgroundColor: "white",
      },
    },
  },
}));
