import {InputBase, makeStyles, Theme, Typography} from "@material-ui/core";
import React from "react";
import SelectButton from "./SelectButton";

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    flexGrow: 1,
    textAlign: "center",
    alignItems: "center",
    minHeight: "40px",
    borderRadius: "10px",
    display: "flex",
    paddingLeft: "10px",
    paddingRight: "10px",
    overflow: "hidden",
    background: "white",
  },
  row: {
    display: "flex",
    margin: "10px",
  },
}));
function DialogItem(props: {
  plan: string;
  checked: boolean;
  id: number;
  onClick: (id: number) => void;
  type?: string;
  otherHandleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  const {plan, checked, id, onClick, type, otherHandleChange} = props;
  const styles = useStyles();
  return (
    <div className={styles.row}>
      {type ? (
        <InputBase
          id="other"
          multiline
          inputProps={{
            min: 0,
            style: {textAlign: "center"},
          }}
          onChange={otherHandleChange}
          value={plan}
          className={styles.item}
        />
      ) : (
        <div className={styles.item}>
          <Typography style={{flexGrow: 1}}>{plan}</Typography>
        </div>
      )}
      <SelectButton checked={checked} id={id} onClick={onClick} />
    </div>
  );
}
export default DialogItem;
