import Header from "components/shell/header";
import Sidebar from "components/shell/sidebar";
import {makeStyles, ThemeProvider} from "@material-ui/core";
import React, {useState} from "react";
import theme from "config/theme";
import {dataContext} from "context/context";
import {IData} from "interface/interface";
import {v4} from "uuid";
import {MenuRoutes} from "hooks/useRouter";

const useStyles = makeStyles({
  appContainer: {
    minHeight: "100vh",
    display: "flex",
    minWidth: "280px",
    maxWidth: "100vw",
    flexDirection: "column",
  },
  bodyContainer: {
    flexGrow: 1,
    display: "flex",
    minHeight: "100%",
  },
  contentContainer: {
    width: "100%",
    maxWidth: "1200px",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "10px",
    boxSizing: "border-box",
    overflowX: "hidden",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
      maxWidth: "1300px",
    },
  },
});

function App() {
  const styles = useStyles();
  let tempDataStore = localStorage.getItem("user_data");
  let tempData: IData = {};
  if (tempDataStore !== null) {
    tempData = JSON.parse(tempDataStore);
  }
  const [data, setBigData] = useState(tempData);
  let device_id = localStorage.getItem("device_id");
  if (device_id === null) {
    device_id = v4();
    localStorage.setItem("device_id", device_id);
  }
  const setData = (data: IData) => {
    localStorage.setItem("user_data", JSON.stringify(data));
    setBigData(data);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.appContainer}>
        <Header />
        <div className={styles.bodyContainer}>
          <Sidebar />
          <div className={styles.contentContainer}>
            <dataContext.Provider value={{data, setData}}>
              <MenuRoutes />
            </dataContext.Provider>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
