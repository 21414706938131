import {Grid, Typography} from "@material-ui/core";
import React, {useContext} from "react";
import {dataContext, financheckContext} from "context/context";
import FormContainer from "components/common/FormContainer";
import FormItem from "./component/FormItem";
import useRouter from "hooks/useRouter";
import Button from "components/common/Button";

const FinanCheck2 = () => {
  const {temp} = useContext(financheckContext);
  const {data, setData} = useContext(dataContext);
  const {plans} = temp;
  const {setPage} = useRouter();

  const submit = () => {
    setData({...data, finanCheck: temp});
    setPage("result");
  };

  const showList = temp.selectPlans.map((id: number) => {
    return (
      <FormItem
        type={2}
        key={id}
        name={plans[id].plan}
        value={plans[id].prepareMoney}
        id={id}
      />
    );
  });

  const SubmitButton = () => (
    <Button
      onClick={submit}
      center
      style={{
        marginTop: "10px",
      }}
    >
      สรุปผล
    </Button>
  );

  return (
    <>
      <FormContainer name="การวางแผนการเงิน">
        <Grid
          container
          spacing={1}
          style={{marginBottom: "5px", textAlign: "center"}}
        >
          <Grid item xs={6}>
            <Typography variant="body1">ประเภทแผน</Typography>
          </Grid>
          <Grid item md={5} xs={6}>
            <Typography variant="body1">จำนวนเงินที่เตรียมไว้แล้ว</Typography>
          </Grid>
          {showList}
        </Grid>
      </FormContainer>

      <SubmitButton />
    </>
  );
};

export default FinanCheck2;
