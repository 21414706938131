import {IconButton, makeStyles, Theme} from "@material-ui/core";
import React from "react";
import AddIcon from "@material-ui/icons/Add";
import color from "config/color";

interface StyleProps {
  backgroundColor: string;
}
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "40px",
    width: "40px",
    marginLeft: "10px",
    backgroundColor: (props: StyleProps) => props.backgroundColor,
    color: color.blue[400],
    "&:hover": {
      backgroundColor: (props: StyleProps) => props.backgroundColor,
    },
  },
}));

const SelectButton = (props: {
  id: number;
  onClick: (id: number) => void;
  checked: boolean;
}) => {
  const {id, onClick, checked} = props;
  const styles = useStyles({
    backgroundColor: checked ? color.blue[100] : "white",
  });

  const clickHandler = () => {
    onClick(id);
  };

  return (
    <IconButton className={styles.container} onClick={clickHandler}>
      <AddIcon />
    </IconButton>
  );
};

export default SelectButton;
