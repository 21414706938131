import CustomMutualFund from "components/pages/retirementPlan/customMutualFund";
import Form from "components/pages/retirementPlan/form";
import Insurance from "components/pages/retirementPlan/Insurance";
import MutualFund from "components/pages/retirementPlan/mutualFund";
import Result from "components/pages/retirementPlan/result";
import {IPageRoutes} from "routes";

const pageRoutes: IPageRoutes = {
  default: {
    component: Form,
    type: "form",
  },
  form: {
    component: Form,
    type: "form",
  },
  mutualFund: {
    component: MutualFund,
    type: "form",
  },
  result: {
    component: Result,
    type: "result",
  },
  insurance: {
    component: Insurance,
    type: "result",
  },
  customMutualFund: {
    component: CustomMutualFund,
    type: "result",
  },
};
export default pageRoutes;
