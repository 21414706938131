import {makeStyles, Theme, Typography} from "@material-ui/core";
import color from "config/color";
import React from "react";
interface IProps {
  data: {name: string; englishName: string; value: string}[];
}
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: "20px 0 20px 0",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: "20px 0 10px 0",
    },
  },
  item: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
      width: "0",
    },
  },
  cell: {
    flexGrow: 1,
    minHeight: "60px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    border: "1px solid white",

    [theme.breakpoints.down("sm")]: {
      width: "0",
    },
  },
  name: {
    backgroundColor: color.blue[300],
    borderRadius: "20px 20px 0 0",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "20px 0 0 20px",
    },
  },
}));
function AnalyzePortTable(props: IProps) {
  const {data} = props;
  const styles = useStyles();
  return (
    <div className={styles.container}>
      {data.map(({name, englishName, value}) => (
        <div key={name} className={styles.item}>
          <div className={styles.cell + " " + styles.name} style={{}}>
            <Typography variant="body1">{name}</Typography>
            <Typography variant="body1">{englishName}</Typography>
          </div>
          <div
            className={styles.cell}
            style={{backgroundColor: color.grey[100]}}
          >
            <Typography variant="body1">{value}</Typography>
          </div>
        </div>
      ))}
    </div>
  );
}

export default AnalyzePortTable;
