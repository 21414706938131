import {makeStyles, Theme, Typography} from "@material-ui/core";
import color from "config/color";
import React from "react";
import FundChart from "../components/FundChart";
interface IProps {
  risk: string;
  percent: {name: string; percent: number}[];
  expectedReturn: string | number;
  SD: number;
  recommend?: boolean;
  selected?: boolean;
  port_id: number;
  onClick?: () => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "200px",
    margin: "5px",
    backgroundColor: color.grey[100],
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "2px 2px 2px #D3D3D3",
    padding: "10px 5px 10px 5px ",
    [theme.breakpoints.down("sm")]: {
      margin: "0 0 10px 0",
      width: "100%",
      maxWidth: "calc(100vw - 30px)",
    },
  },
  title: {
    marginTop: "20px",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      wordBreak: "break-word",
      whiteSpace: "normal",
      minHeight: "65px",
    },
  },
  chart: {
    display: "flex",
    justifyContent: "center",
    flexShrink: 1,
    margin: "15px 0 15px 0",
  },
  data: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
  },
}));
const Content = (props: {
  thaiName: string;
  englishName: string;
  value: string | number;
}) => {
  const {thaiName, englishName, value} = props;
  return (
    <div style={{marginTop: "1rem", textAlign: "center"}}>
      <Typography style={{marginBottom: "-2px"}}>{thaiName}</Typography>
      <Typography>{englishName}</Typography>
      <Typography style={{marginTop: "1rem"}}>{value}</Typography>
    </div>
  );
};
function Port(props: IProps) {
  const {
    risk,
    percent,
    expectedReturn,
    SD,
    recommend,
    selected,
    port_id,
    onClick,
  } = props;
  const styles = useStyles();
  return (
    <div
      className={styles.container}
      style={{
        border: selected ? "3px solid #284893" : `3px solid ${color.grey[100]}`,
      }}
      key={port_id}
      onClick={onClick}
    >
      <Typography variant="body1" className={styles.title}>
        {risk}
      </Typography>
      <div className={styles.data}>
        <div className={styles.chart}>
          <FundChart data={percent} />
        </div>
        <div>
          <Content
            thaiName="ผลตอบเทนที่คาดหวัง"
            englishName="Expected Return"
            value={expectedReturn}
          />
          <Content thaiName="ความผันผวน" englishName="(S.D.)" value={SD} />
        </div>
      </div>
      {recommend && (
        <Typography color="primary" style={{margin: "10px 0 10px 0"}}>
          พอร์ตที่แนะนำ
        </Typography>
      )}
    </div>
  );
}

export default Port;
