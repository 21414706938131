import {IconButton, makeStyles} from "@material-ui/core";
import color from "config/color";
import React from "react";
import {TiPlus} from "react-icons/ti";

const useStyles = makeStyles(() => ({
  plusButton: {
    backgroundColor: color.blue[400],
    "&:hover": {
      backgroundColor: color.blue[400],
    },
  },
}));

const AddFundButton = ({addFund}: {addFund: () => void}) => {
  const styles = useStyles();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "10px",
      }}
    >
      <IconButton
        color="primary"
        className={styles.plusButton}
        onClick={addFund}
      >
        <TiPlus color="white" />
      </IconButton>
    </div>
  );
};

export default AddFundButton;
