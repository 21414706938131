import {Grid} from "@material-ui/core";
import TableTypo from "components/pages/finanCheck/component/TableTypo";

function ResultItem(props: {
  name: string;
  prepareMoney: number;
  useMoney: number;
}) {
  const {name, prepareMoney, useMoney} = props;
  return (
    <>
      <Grid container spacing={1} style={{marginBottom: "5px"}}>
        <TableTypo md={6} xs={12}>
          {name}
        </TableTypo>
        <TableTypo md={2} xs={4}>
          {useMoney.toLocaleString()}
        </TableTypo>
        <TableTypo md={2} xs={4}>
          {prepareMoney.toLocaleString()}
        </TableTypo>
        <TableTypo md={2} xs={4}>
          {Math.max(0, useMoney - prepareMoney).toLocaleString()}
        </TableTypo>
      </Grid>
    </>
  );
}
export default ResultItem;
