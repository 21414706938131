import {
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import color from "../../../config/color";

interface IProps {
  data: (string | number)[];
  headStyle?: React.CSSProperties;
  rowHeadStyle?: React.CSSProperties;
  head?: boolean;
  lastRow?: boolean;
  firstRow?: boolean;
  highlight?: boolean[];
}
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  cellContainer: {
    flexGrow: 1,
    width: "0",
    minHeight: "60px",
    backgroundColor: color.grey[100],
    border: "1px solid white",
    display: "flex",
  },
  cell: {
    margin: "2px",
    width: "100%",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
}));

function Row(props: IProps) {
  const {data, head, headStyle, rowHeadStyle, lastRow, firstRow, highlight} =
    props;
  const column = data.length;
  const styles = useStyles({column});
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={styles.container}>
      {data.map((cell, idx) => {
        let style: React.CSSProperties | undefined = {};
        // Table Head
        if (head) {
          if (idx === 0) {
            if (isSm) return <></>;
            return <div key={idx} style={{flexGrow: 1, flexBasis: 0}} />;
          } else {
            style = headStyle;
            if (idx === 1) {
              style = {
                ...style,
                borderRadius:
                  style && style.borderRadius
                    ? style.borderRadius
                    : "20px 0 0 0",
              };
            } else if (idx === data.length - 1) {
              style = {
                ...style,
                borderRadius:
                  style && style.borderRadius
                    ? style.borderRadius
                    : "0 20px 0 0",
              };
            }
          }
        }

        // Row Head
        if (idx === 0) {
          style = rowHeadStyle;
          if (isSm) {
            style = {
              ...style,
              width: "100%",
              flexBasis: "unset",
              borderRadius: "0 0 0 0",
            };
          }
        }

        // First Row
        if (firstRow) {
          if (idx === 0 && !isSm) {
            style = {
              ...style,
              borderRadius:
                style && style.borderRadius ? style.borderRadius : "20px 0 0 0",
            };
          }
        }

        // Last Row
        if (lastRow) {
          if (idx === 0 && !isSm) {
            style = {
              ...style,
              borderRadius:
                style && style.borderRadius ? style.borderRadius : "0 0 0 20px",
            };
          } else if (idx === 1 && isSm) {
            style = {
              ...style,
              borderRadius:
                style && style.borderRadius ? style.borderRadius : "0 0 0 20px",
            };
          } else if (idx === data.length - 1) {
            style = {
              ...style,
              borderRadius:
                style && style.borderRadius ? style.borderRadius : "0 0 20px 0",
            };
          }
        }

        return (
          <div className={styles.cellContainer} style={style} key={idx}>
            <div
              className={styles.cell}
              style={{
                // Highlight
                backgroundColor:
                  highlight && highlight[idx] ? `${color.blue[300]}88` : "",
              }}
            >
              <Typography variant="body1">
                {cell ? cell.toLocaleString() : ""}
              </Typography>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Row;
