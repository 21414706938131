import {Box, Grid, makeStyles, Theme, Typography} from "@material-ui/core";
import React, {useContext} from "react";
import {dataContext} from "context/context";
import FormContainer from "components/common/FormContainer";
import {ISumAssured} from "interface/interface";
import FormItem from "./component/FormItem";
import useRouter from "hooks/useRouter";
import GenderInput from "components/common/GenderInput";
import NumberInput from "components/common/NumberInput";
import {Form, Formik} from "formik";
import {useCallback} from "react";
import Button from "components/common/Button";
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: "900px",
    margin: "auto",
    marginTop: "20px",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  button: {
    marginBottom: "10px",
    marginTop: "20px",
    [theme.breakpoints.up("lg")]: {
      minWidth: "200px",
    },
  },
  content: {
    display: "flex",
    alignItems: "flex-end",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
}));
function SumAssuredForm() {
  const {data, setData} = useContext(dataContext);

  // get initial value
  const initialValues = data.sumAssured || {
    gender: "male",
    age: 30,
    income: 40000,
    outcome: 30000,
    debt: 1000000,
    adaptPeriod: 5,
    inflaRate: 0.9,
  };

  // handle submit
  const {setPage} = useRouter();
  const handleSubmit = useCallback(
    (value: ISumAssured) => {
      setData({...data, sumAssured: value});
      setPage("result");
    },
    [data, setData, setPage]
  );

  const styles = useStyles();
  return (
    <div className={styles.container}>
      <Typography variant="h1" color="primary">
        ทุนประกันชีวิตที่เหมาะสม
      </Typography>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <div className={styles.content}>
            <FormContainer name="กรอกข้อมูลสำคัญ" style={{flexGrow: 1}}>
              <Grid container spacing={2}>
                <FormItem
                  xs={6}
                  label="เพศ"
                  input={<GenderInput name="gender" />}
                />
                <FormItem
                  xs={6}
                  label="อายุ"
                  input={<NumberInput name="age" />}
                />
                <FormItem
                  xs={12}
                  label="รายรับ"
                  unit="บาท/เดือน"
                  input={<NumberInput name="income" />}
                />
                <FormItem
                  xs={12}
                  label="รายจ่าย"
                  unit="บาท/เดือน"
                  input={<NumberInput name="outcome" />}
                />
                <FormItem
                  xs={12}
                  label="หนี้สิน"
                  unit="บาท"
                  input={<NumberInput name="debt" />}
                />
                <FormItem
                  xs={6}
                  label="ระยะเวลาปรับตัว"
                  input={<NumberInput name="adaptPeriod" type="float" />}
                />
                <FormItem
                  xs={6}
                  label="อัตราเงินเฟ้อ"
                  input={<NumberInput name="inflaRate" type="float" />}
                />
              </Grid>
            </FormContainer>
            <Box>
              <Button type="submit" className={styles.button}>
                ประเมินผล
              </Button>
            </Box>
          </div>
        </Form>
      </Formik>
    </div>
  );
}
export default SumAssuredForm;
