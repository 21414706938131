import {createContext} from "react";
import {
  IAssetClass,
  IData,
  IFinanCheck,
  IRetirePlan,
} from "interface/interface";
const DataObject: IData = {};

export const dataContext = createContext({
  data: DataObject,
  setData: (data: IData) => {},
});

export const financheckContext = createContext({
  temp: {
    isPrepared: [0],
    plans: [{plan: "", check: false, useMoney: 0, prepareMoney: 0}],
    numplans: 0,
    selectPlans: [0],
  },
  setTemp: (temp: IFinanCheck) => {},
});
const fundList: IAssetClass[] = [];
export const retireplanContext = createContext({
  temp: {
    gender: "male",
    age: 0,
    retriment_age: 0,
    death_age: 0,
    outcome: 0,
    inflationrate: 0,
    retryment_rq: 0,
    need_insurance: 0,
    need_insurance_money: 0,
    need_mutualfund: 0,
    need_mutualfund_money: 0,
    risk_data: {
      risk_form_id: 0,
      risk_score: 0,
      risk_assessed: 0,
    },
    risk_accepted: 0,
    insure_plan: {
      plan_id: 0,
      plan: "",
      premium: 0,
      pay_period: 0,
      period: "",
    },
    fund_port: {
      port_id: -1,
      name: "custom",
      stat: {
        expected: 0,
        sd: 0,
      },
      lists: fundList,
    },
  },
  setTemp: (temp: IRetirePlan) => {},
});
