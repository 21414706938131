import {makeStyles, Theme, Typography} from "@material-ui/core";
import NumberInput from "components/common/NumberInput";
import color from "config/color";
import React from "react";

interface IProps {
  value: number;
  onChange: (value: number) => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  inputContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    borderRadius: "20px",
    backgroundColor: color.grey[100],
    padding: "10px",
    flexWrap: "wrap",
    margin: "10px 0 10px 0",
  },
  inputLabel: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
    },
  },
}));

function MoneyInput(props: IProps) {
  const {onChange, value} = props;
  const styles = useStyles();
  return (
    <div className={styles.inputContainer}>
      <Typography variant="body1" className={styles.inputLabel}>
        ต้องการมีเงินใช้หลังเกษียณเดือนละ
      </Typography>
      <NumberInput value={value} onChange={onChange} unit="บาท" />
    </div>
  );
}

export default MoneyInput;
