import {Box, makeStyles, Theme, Typography} from "@material-ui/core";
import Button from "components/common/Button";
import color from "config/color";
import React from "react";
const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: "calc(50% - 7px)",
    minHeight: "40px",
    display: "inline-block",
    textTransform: "none",
    color: "white",
    borderRadius: "10px",
  },
  need: {
    backgroundColor: (props: {type: boolean}) =>
      props.type ? color.blue[100] : color.blue[400],
    "&:hover": {
      backgroundColor: (props: {type: boolean}) =>
        props.type ? color.blue[100] : color.blue[400],
    },
  },
  potential: {
    backgroundColor: (props: {type: boolean}) =>
      props.type ? color.blue[400] : color.blue[100],
    "&:hover": {
      backgroundColor: (props: {type: boolean}) =>
        props.type ? color.blue[400] : color.blue[100],
    },
  },
}));
function TypeInput(props: {type: boolean; setType: (type: boolean) => void}) {
  var {type, setType} = props;
  const styles = useStyles({
    type: type,
  });
  return (
    <Box display="flex" justifyContent="space-between">
      <Button
        onClick={() => {
          setType(false);
        }}
        className={styles.button + " " + styles.need}
      >
        <Typography variant="body1">ตามความจำเป็น</Typography>
        <Typography variant="body2">Need base</Typography>
      </Button>
      <Button
        onClick={() => {
          setType(true);
        }}
        className={styles.button + " " + styles.potential}
      >
        <Typography variant="body1">ตามศักยภาพ</Typography>
        <Typography variant="body2">Potential base</Typography>
      </Button>
    </Box>
  );
}
export default TypeInput;
