import React, {useContext, useState} from "react";
import {PageRoutes} from "hooks/useRouter";
import {dataContext, retireplanContext} from "context/context";
import {IAssetClass, IRetirePlan} from "interface/interface";

function RetirementPlan() {
  const {data} = useContext(dataContext);
  const fundList: IAssetClass[] = [];
  //default data
  let tempData: IRetirePlan = {
    gender: "male",
    age: 40,
    retriment_age: 60,
    death_age: 80,
    outcome: 30000,
    inflationrate: 1.5,
    retryment_rq: 40000,
    need_insurance: 50,
    need_insurance_money: -1,
    need_mutualfund: 50,
    need_mutualfund_money: -1,
    risk_data: {
      risk_form_id: 0,
      risk_score: 0,
      risk_assessed: 0,
    },
    risk_accepted: -1,
    insure_plan: {
      plan_id: 1,
      plan: "",
      premium: -1,
      pay_period: -1,
      period: "",
    },
    fund_port: {
      port_id: -1,
      name: "custom",
      stat: {
        expected: 0,
        sd: 0,
      },
      lists: fundList,
    },
  };
  if (!!data.retirePlan) {
    tempData = data.retirePlan;
  }
  const [temp, setTemp] = useState(tempData);
  return (
    <retireplanContext.Provider value={{temp, setTemp}}>
      <PageRoutes />
    </retireplanContext.Provider>
  );
}

export default RetirementPlan;
