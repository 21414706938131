import {Typography, Box, Slider, makeStyles, Theme} from "@material-ui/core";
import {useField} from "formik";
import React from "react";
const useStyles = makeStyles((theme: Theme) => ({
  sliderLabel: {
    maxWidth: "100%",
    fontSize: "1.25rem",
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      flexShrink: 1,
    },
  },
  sliderContainer: {
    display: "flex",
    flexGrow: 1,
    width: "100%",
    flexWrap: "wrap",
    marginTop: "15px",
  },
}));

interface IProps {
  name: string;
}

function SliderInput(props: IProps) {
  const styles = useStyles();
  const {name} = props;
  const [field, , helpers] = useField(name);

  const handleChange = (event: any, newValue: number | number[]) => {
    helpers.setValue(newValue);
  };

  return (
    <div className={styles.sliderContainer}>
      <Typography
        className={styles.sliderLabel}
        style={{marginRight: "20px", marginBottom: "10px"}}
      >
        ต้องการออมเงินด้วย
      </Typography>
      <Box display="flex" flexGrow={1} width="min(100%, 500px)">
        <Typography className={styles.sliderLabel}>ประกันชีวิต</Typography>
        <Slider
          style={{flexGrow: 1, margin: "0 15px 0 15px"}}
          valueLabelFormat={(value) => `${value}%`}
          aria-labelledby="discrete-slider-small-steps"
          step={10}
          marks
          min={0}
          max={100}
          value={field.value}
          onChange={handleChange}
          valueLabelDisplay="auto"
        />
        <Typography className={styles.sliderLabel}>กองทุนรวม</Typography>
      </Box>
    </div>
  );
}

export default SliderInput;
