import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React, {useContext, useEffect, useState} from "react";
import {dataContext, financheckContext} from "../../../context/context";
import FormItem from "./component/FormItem";
import AddIcon from "@material-ui/icons/Add";
import Form1Dialog from "./component/Form1Dialog";
import FormContainer from "components/common/FormContainer";
import {IFinanPlan} from "interface/interface";
import color from "config/color";
import useRouter from "hooks/useRouter";
import Button from "components/common/Button";
const useStyles = makeStyles((theme: Theme) => ({
  button: {
    backgroundColor: "white",
    color: color.blue[400],
    marginBottom: "-10px",
    display: "inline",
    "&:hover": {
      backgroundColor: "white",
    },
  },
}));
const Form1 = () => {
  const {setPage} = useRouter();
  const styles = useStyles();
  const {temp, setTemp} = useContext(financheckContext);
  const {data, setData} = useContext(dataContext);

  const [selected, setSelected] = useState<IFinanPlan[]>([]);
  const setSeletedItems = () => {
    const select: IFinanPlan[] = [];
    const {plans} = temp;
    plans.forEach((item: IFinanPlan, id: number) => {
      if (item.check) {
        item.id = id;
        select.push(item);
      }
    });
    setSelected(select);
  };

  useEffect(() => {
    setSeletedItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isDialogOpen, setDialogOpen] = useState(false);
  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setSeletedItems();
    setDialogOpen(false);
  };

  const submit = () => {
    const select: number[] = [];
    selected.forEach((item: IFinanPlan) => {
      select.push(item.id!);
    });
    setTemp({...temp, selectPlans: select});
    setData({...data, finanCheck: {...temp, selectPlans: select}});
    setPage("form2");
  };

  const showList = selected.map((item: IFinanPlan) => {
    return (
      <FormItem
        type={1}
        key={item.plan}
        name={item.plan}
        value={item.useMoney}
        id={item.id!}
      />
    );
  });

  const AddPlanButton = () => (
    <Box display="flex" marginTop="10px">
      <IconButton
        aria-label="add"
        className={styles.button}
        onClick={openDialog}
      >
        <AddIcon />
      </IconButton>
      <Typography
        style={{
          display: "inline",
          paddingTop: "10px",
          paddingLeft: "10px",
        }}
      >
        เพิ่มแผน
      </Typography>
    </Box>
  );

  return (
    <>
      <Form1Dialog open={isDialogOpen} onClose={closeDialog} />

      <FormContainer name="การวางแผนการเงิน">
        <Grid container spacing={1} style={{textAlign: "center"}}>
          <Grid item xs={6}>
            <Typography variant="body1">ประเภทแผน</Typography>
          </Grid>
          <Grid item xs={6} md={5}>
            <Typography variant="body1">จำนวนเงินที่ต้องเตรียม</Typography>
          </Grid>
          {showList}
        </Grid>
        <AddPlanButton />
      </FormContainer>

      <Box maxWidth="900px" margin="auto">
        <Button onClick={submit} style={{float: "right", marginTop: "10px"}}>
          ต่อไป
        </Button>
      </Box>
    </>
  );
};

export default Form1;
