import {makeStyles, Theme} from "@material-ui/core";

import useRouter from "hooks/useRouter";
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    cursor: "pointer",
    borderRadius: "40px",
    [theme.breakpoints.down("md")]: {
      borderRadius: "20px",
    },
  },
}));

const menu = [
  "finanCheck",
  "healthCare",
  "retirementPlan",
  "sumAssured",
  "educationPlan",
  "legacyPlan",
  "taxPlan",
  "savingPlan",
];
function Card(props: {id: number}) {
  const styles = useStyles();
  const {id} = props;
  const image = require("local/home/" + String(id) + ".png").default;
  const {setMenu} = useRouter();

  const HandleClick = () => {
    setMenu(menu[id - 1]);
  };

  return (
    <img
      src={image}
      onClick={HandleClick}
      className={styles.container}
      alt=""
    />
  );
}
export default Card;
