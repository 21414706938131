import Button from "components/common/Button";
import React, {useContext, useEffect, useState} from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";
import Port from "./Port";
import {RiArrowRightSLine, RiArrowLeftSLine} from "react-icons/ri";
import {Hidden, makeStyles, Theme, Typography} from "@material-ui/core";
import axios from "axios";
import {IFundRetirePort} from "interface/interface";
import {dataContext, retireplanContext} from "context/context";
import useRouter from "hooks/useRouter";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: "250px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));
function MutualFund() {
  const {setPage} = useRouter();
  const styles = useStyles();
  const {temp, setTemp} = useContext(retireplanContext);
  const {data, setData} = useContext(dataContext);

  const [recPorts, setRecPorts] = useState([]);
  const [selected, setSelected] = useState(-1);

  const ports = recPorts.map((port: IFundRetirePort, index: number) => {
    const percents: {[key: number]: number} = {};
    let allweight = 0;
    port.lists.forEach((asset) => {
      asset.a.forEach((percent) => {
        const aType: number = parseFloat(Object.keys(percent)[0]);
        const aPercent = percent[aType];
        if (!percents[aType]) {
          percents[aType] = 0;
        }
        percents[aType] += aPercent * asset.w;
      });
      allweight += asset.w;
    });
    const percentList: {name: string; percent: number}[] = [];
    for (const [key, value] of Object.entries(percents)) {
      percentList.push({
        name: key.toString(),
        percent: value / allweight,
      });
    }
    return (
      <Port
        risk={port.name}
        percent={percentList}
        expectedReturn={port.stat.expected}
        SD={port.stat.sd}
        recommend
        selected={selected === index}
        key={index}
        port_id={port.port_id}
        onClick={() => setSelected(index)}
      />
    );
  });

  const getResult = async () => {
    let device_id = localStorage.getItem("device_id");
    const url = "https://fhc.insuretechthailand.com/ins/retirement/cal";
    if (!!temp) {
      const {
        age,
        gender,
        retriment_age,
        death_age,
        outcome,
        inflationrate,
        retryment_rq,
        risk_data,
        need_insurance,
        risk_accepted,
      } = temp;
      const info = {
        device_id: device_id, // device_id ของ local
        cal_type: "fund_recommend", // function ที่เรียกใช้   ins_recommend , fund_recommend
        sex: gender, //เพศ
        age, //อายุปัจจุบัน
        retriment_age, // defualt 60
        death_age, // defualt 85
        outcome, //รายจ่ายปัจจุบัน
        inflationrate, //อัตราเงินเฟ้อ
        retryment_sh:
          outcome * 0.7 * (1 + inflationrate) ** (retriment_age - age), //เงินบำนาญขั้นต่ำที่ควรมี   =  outcome * 0.7 ((1+(inflation))**(retriment_age - Age))
        retryment_rq, //เงินบำนาญที่ต้องการรมี  ระบุเอง
        risk_form_id: risk_data.risk_form_id, //not edit  default จาก response.risk_form_id
        risk_assessed: risk_data.risk_assessed, //not edit  default จาก response.risk_assessed
        risk_accepted, //ความเสี่ยงจากการลงทุนที่รับได้ default จาก response.risk_assessed   แต่เลือกเปลี่ยนเองได้
        need_insurance: need_insurance, //  0 - 100 need_insurance + need_mutualfund =  100
        need_mutualfund: 100 - need_insurance, //  0 - 100 need_insurance + need_mutualfund =  100
        time: Date.now(),
        calculateAT: new Date().toLocaleString(),
      };
      const response = await axios.post(url, info);
      if (!!response.data.fund_ports) {
        setRecPorts(response.data.fund_ports);
      }
    }
  };

  useEffect(() => {
    getResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (page: string) => {
    let selectPlan: IFundRetirePort = temp.fund_port;
    if (selected != -1) {
      selectPlan = recPorts[selected];
    }
    setTemp({...temp, fund_port: selectPlan});
    setData({...data, retirePlan: {...temp, fund_port: selectPlan}});
    setPage(page);
  };
  return (
    <div style={{marginTop: "20px"}}>
      <Typography variant="h1" color="primary" style={{marginBottom: "20px"}}>
        วางแผนเกษียณ
      </Typography>
      <Hidden smDown>
        <ScrollMenu
          data={ports}
          arrowLeft={
            <div>
              <RiArrowLeftSLine size="2.5rem" />
            </div>
          }
          arrowRight={<RiArrowRightSLine size="2.5rem" />}
          wrapperStyle={{margin: "auto"}}
        />
      </Hidden>
      <Hidden mdUp>{ports}</Hidden>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: "10px",
        }}
      >
        <Button
          onClick={() => handleClick("customMutualFund")}
          className={styles.button}
        >
          จัดพอร์ตด้วยตัวเอง
        </Button>
        <Button onClick={() => handleClick("result")} className={styles.button}>
          ตกลง
        </Button>
      </div>
    </div>
  );
}

export default MutualFund;
