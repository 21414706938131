import {InputBase, makeStyles, Typography} from "@material-ui/core";
import withFormik from "components/hoc/withFormik";
import {FieldInputProps} from "formik";
import React, {useCallback} from "react";

interface IProps {
  value: number;
  onChange: (value: number) => void;
  field?: FieldInputProps<any>;

  type?: "int" | "float";
  unit?: string;
  className?: string;
  style?: React.CSSProperties;
}

function NumberInput(props: IProps) {
  const {unit, className, style, onChange, value, field} = props;
  const type = props.type || "int";
  const styles = useStyles();

  // Add comma to string
  const addComma = useCallback((str: string | number) => {
    var parts = String(str).split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }, []);

  // handle Change
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.replaceAll(",", "");
      const floatRegex = /^\d*[.]?\d*$/;
      const intRegex = /^\d+$/;

      // check if value is float or int
      if (
        (type === "float" && floatRegex.test(value)) ||
        (type === "int" && intRegex.test(value))
      ) {
        onChange!(Number(value));
      }

      // check if value is blank
      if (value === "") {
        onChange!(0);
      }
    },
    [onChange, type]
  );

  return (
    <div className={styles.inputContainer + " " + className} style={style}>
      <InputBase
        className={styles.input}
        inputProps={{
          min: 0,
          style: {textAlign: "center"},
        }}
        multiline
        type="number"
        {...field}
        onChange={handleChange}
        value={value === 0 ? "" : addComma(value)}
      />
      {unit && <Typography className={styles.unit}>{unit}</Typography>}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    display: "flex",
    background: "white",
    borderRadius: "10px",
    flexDirection: "row",
    alignItems: "center",
  },
  input: {
    flexGrow: 1,
    textAlign: "center",
    alignItems: "center",
    minHeight: "40px",
    height: "100%",
    paddingLeft: "5px",
    overflow: "hidden",
  },
  unit: {
    flexShrink: 0,
    padding: "0 5px 0 5px",
  },
}));

export default withFormik(NumberInput);
