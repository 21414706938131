import {makeStyles, Theme, Typography} from "@material-ui/core";
import color from "config/color";
import {retireplanContext} from "context/context";
import React, {useContext} from "react";
const useStyles = makeStyles((theme: Theme) => ({
  plan: {
    textAlign: "center",
    padding: "10px",
    backgroundColor: color.blue[100],
    borderRadius: "10px",
  },
  text: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
}));

function Insurance() {
  const styles = useStyles();
  const {temp} = useContext(retireplanContext);
  return (
    <div style={{flexGrow: 1, margin: "10px 0 20px 0"}}>
      <Typography variant="h3" style={{textAlign: "center"}}>
        ประกันชีวิต
      </Typography>
      <div style={{display: "flex", justifyContent: "center", margin: "30px"}}>
        <Typography variant="body1" className={styles.plan} style={{}}>
          {temp.insure_plan.plan}
        </Typography>
      </div>
      <div>
        <Typography variant="body1" className={styles.text}>
          เบี้ยประกัน {temp.insure_plan.premium} บาท
        </Typography>
        <Typography
          variant="body1"
          className={styles.text}
          style={{margin: "15px 0 15px 0"}}
        >
          ระยะเวลาจ่ายเบี้ยฯ {temp.insure_plan.pay_period} ปี
        </Typography>
        <Typography className={styles.text} variant="body1">
          ช่วงอายุรับเงินบำนาญ {temp.insure_plan.period}
        </Typography>
      </div>
    </div>
  );
}

export default Insurance;
