import {makeStyles, Button, withStyles} from "@material-ui/core";
import withFormik from "components/hoc/withFormik";
import color from "config/color";
import React from "react";

interface IProps {
  value: string;
  onChange: (gender: string) => void;
}

function GenderInput(props: IProps) {
  const {onChange, value} = props;
  const styles = useStyles();

  return (
    <>
      <GenderButton
        onClick={() => onChange("male")}
        className={value === "male" ? styles.selected : ""}
        style={{
          borderRadius: "10px 0px 0px 10px",
        }}
      >
        ชาย
      </GenderButton>
      <GenderButton
        onClick={() => onChange("female")}
        className={value === "female" ? styles.selected : ""}
        style={{
          borderRadius: "0px 10px 10px 0px",
        }}
      >
        หญิง
      </GenderButton>
    </>
  );
}

export default withFormik(GenderInput);

const GenderButton = withStyles({
  root: {
    width: "50%",
    minHeight: "40px",
    borderRadius: "0",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
  },
})(Button);

const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: color.blue[100],
    "&:hover": {
      backgroundColor: color.blue[100],
    },
  },
}));
