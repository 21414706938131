import {Grid, GridSize, makeStyles, Theme, Typography} from "@material-ui/core";
import React from "react";
interface Props {
  md?: GridSize;
  xs?: GridSize;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}
const useStyles = makeStyles((theme: Theme) => ({
  column: {
    minWidth: "100%",
    textAlign: "center",
    alignItems: "center",
    minHeight: "40px",
    height: "100%",
    borderRadius: "10px",
    display: "flex",
    alignContent: "center",
    overflow: "hidden",
    background: "white",
  },
  typo: {
    flexGrow: 1,
    paddingLeft: "10px",
    paddingRight: "10px",
    wordWrap: "break-word",
    maxWidth: "calc(100% - 20px)",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "5px",
      paddingRight: "5px",
      maxWidth: "calc(100% - 10px)",
    },
  },
  backgroundWhite: {},
}));
function TableTypo(props: Props) {
  const {md, xs, style, children} = props;
  const styles = useStyles();
  return (
    <Grid item md={md} xs={xs} style={style}>
      <div className={styles.column + " " + styles.backgroundWhite}>
        <Typography className={styles.typo}>{children}</Typography>
      </div>
    </Grid>
  );
}
export default TableTypo;
