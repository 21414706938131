import {makeStyles} from "@material-ui/core";
import {Theme} from "@material-ui/core";
import {Box, Typography} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import color from "config/color";
import React, {useState} from "react";
const useStyles = makeStyles((theme: Theme) => ({
  caption: {
    backgroundColor: "white",
    margin: "10px",
    borderRadius: "20px",
    padding: "10px",
    cursor: "pointer",
  },
  planA: {
    color: (props: any) => (props.type == 0 ? "white" : color.blue[400]),
    backgroundColor: (props: {type: number}) =>
      props.type == 0 ? color.blue[400] : "white",
  },
  planB: {
    color: (props: any) => (props.type == 1 ? "white" : color.grey[400]),
    backgroundColor: (props: {type: number}) =>
      props.type == 1 ? color.grey[400] : "white",
  },
}));

function PlanButton(props: any) {
  const {plan, setData, dataList, data} = props;
  const [type, setType] = useState(-1);
  const styles = useStyles({type});
  const updateData = (type: number) => {
    var tmp = [...data];
    if (type == -1) {
      tmp[0].data = dataList[0];
      tmp[1].data = dataList[1];
    }
    if (type == 0) {
      tmp[0].data = dataList[0];
      tmp[1].data = {
        room: 0,
        disease: 0,
        health: 0,
      };
    }
    if (type == 1) {
      tmp[1].data = dataList[1];
      tmp[0].data = {
        room: 0,
        disease: 0,
        health: 0,
      };
    }
    setData(tmp);
  };
  return (
    <Box bgcolor={color.grey[100]} borderRadius="20px">
      <Typography
        variant="h3"
        className={styles.caption + " " + styles.planA}
        onClick={() => {
          if (type == 0) {
            setType(-1);
            updateData(-1);
          } else {
            setType(0);
            updateData(0);
          }
        }}
      >
        <FiberManualRecordIcon fontSize="small" />
        {plan[0]}
      </Typography>
      <Typography
        variant="h3"
        className={styles.caption + " " + styles.planB}
        onClick={() => {
          if (type == 1) {
            setType(-1);
            updateData(-1);
          } else {
            setType(1);
            updateData(1);
          }
        }}
      >
        <FiberManualRecordIcon fontSize="small" />
        {plan[1]}
      </Typography>
    </Box>
  );
}
export default PlanButton;
