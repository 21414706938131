import {Radio, Typography} from "@material-ui/core";
import Button from "components/common/Button";
import FormContainer from "components/common/FormContainer";
import color from "config/color";
import RpqQuestion from "local/retirementPlan/RpqQuestion";

function RPQ(props: {closeDialog: () => void}) {
  return (
    <FormContainer
      name="แบบประเมินความเสี่ยงที่ยอมรับได้
      (Risk Profile Questionaire: RPQ)"
      style={{width: "95%"}}
      contentStyle={{
        backgroundColor: "white",
        overflow: "auto",
        maxHeight: "80vh",
      }}
    >
      <QuestionPart />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
          flexWrap: "wrap",
        }}
      >
        <div style={{display: "flex"}}>
          <Typography>รวมคะแนน</Typography>
          <Typography
            style={{
              backgroundColor: color.grey[200],
              borderRadius: "10px",
              padding: "0 10px 0 10px",
              margin: "0 10px 0 10px",
            }}
          >
            xx
          </Typography>
        </div>
        <div style={{display: "flex"}}>
          <Typography>ระดับความเสี่ยงที่ยอมรับได้</Typography>
          <Typography
            style={{
              backgroundColor: color.grey[200],
              borderRadius: "10px",
              padding: "0 10px 0 10px",
              margin: "0 10px 0 10px",
            }}
          >
            xx
          </Typography>
        </div>
      </div>
      <Button
        onClick={props.closeDialog}
        style={{display: "block", margin: "auto", marginTop: "20px"}}
      >
        กลับหน้ากรอกข้อมูล
      </Button>
    </FormContainer>
  );
}

const Question = (props: {question: string; choices: string[]}) => {
  return (
    <div>
      <Typography>{props.question}</Typography>
      <div style={{display: "flex", flexWrap: "wrap"}}>
        {props.choices.map((choice, idx) => (
          <div
            key={idx}
            style={{flexGrow: 1, display: "flex", alignItems: "center"}}
          >
            <Radio color="primary" />
            <Typography variant="body2">{choice}</Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

const QuestionPart = () => (
  <>
    <Typography variant="body1" style={{margin: "10px 0 10px 0"}}>
      คำถามข้อ 1 - 10 ใช้เพื่อประเมินความเหมาะสมในการลงทุนของท่าน
    </Typography>
    {RpqQuestion.map((data, idx) => (
      <Question
        key={idx}
        question={`${idx + 1}. ${data.question}`}
        choices={data.choices}
      />
    ))}
  </>
);

export default RPQ;
