import ResultItem from "./component/ResultItem";
import {Box, Grid, Hidden, Typography} from "@material-ui/core";
import React, {useContext} from "react";
import {dataContext} from "context/context";
import FormContainer from "components/common/FormContainer";
import {IFinanCheck} from "interface/interface";
const FinanCheckResult = () => {
  const {data} = useContext(dataContext);

  const finanCheck: IFinanCheck = data.finanCheck!;

  const {selectPlans, plans} = finanCheck;
  const resultList = selectPlans.map((id: number) => {
    const item = plans[id];
    return (
      <ResultItem
        key={item.plan}
        name={item.plan}
        prepareMoney={item.prepareMoney}
        useMoney={item.useMoney}
      />
    );
  });

  const TableHead = () => (
    <>
      <Grid item md={6} xs={12}>
        <Typography variant="body1">ประเภทแผน</Typography>
      </Grid>
      <Hidden mdUp>
        <Grid item xs={12}>
          <Box bgcolor="black" height="1px" />
        </Grid>
      </Hidden>
      <Grid item md={2} xs={4}>
        <Typography variant="body1">จำนวนเงินที่ต้องเตรียม</Typography>
      </Grid>
      <Grid item md={2} xs={4}>
        <Typography variant="body1">เตรียมไว้แล้ว</Typography>
      </Grid>
      <Grid item md={2} xs={4}>
        <Typography variant="body1">ขาดอีก</Typography>
      </Grid>
    </>
  );

  return (
    <FormContainer name="การวางแผนการเงิน">
      <Grid container spacing={1} style={{textAlign: "center"}}>
        <TableHead />
        {resultList}
      </Grid>
    </FormContainer>
  );
};
export default FinanCheckResult;
