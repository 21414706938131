import {Box, makeStyles, Theme, Typography} from "@material-ui/core";
import axios from "axios";
import Button from "components/common/Button";
import Table from "components/common/table";
import color from "config/color";
import useRouter from "hooks/useRouter";
import {dataContext, retireplanContext} from "context/context";
import React, {useContext, useEffect, useState} from "react";
import MoneyInput from "./components/MoneyInput";

const useStyles = makeStyles((theme: Theme) => ({
  planButtonContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
  },
  planButton: {
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const idtoplan: {[key: string]: string} = {
  "1": "AIA_ANNUITY_FIX",
  "2": "MUANG_THAI_8560",
  "3": "MUANG_THAI_9960",
};
function Insurance() {
  const {setPage} = useRouter();
  const {temp, setTemp} = useContext(retireplanContext);
  const {data, setData} = useContext(dataContext);
  const styles = useStyles();
  const defaultPlan = !!temp.insure_plan
    ? temp.insure_plan.plan_id.toString()
    : "1";
  const [plan, setPlan] = useState(defaultPlan);
  const [allPlan, setAllPlan] = useState([]);
  const [formData, setFormData] = useState({
    need_insurance_money: temp.need_insurance_money,
  });
  const getResult = async () => {
    let device_id = localStorage.getItem("device_id");
    const url = "https://fhc.insuretechthailand.com/ins/retirement/cal";
    if (!!temp) {
      const {
        age,
        gender,
        retriment_age,
        death_age,
        outcome,
        inflationrate,
        retryment_rq,
        risk_data,
        need_insurance,
        risk_accepted,
      } = temp;
      const info = {
        device_id: device_id, // device_id ของ local
        cal_type: "ins_recommend", // function ที่เรียกใช้   ins_recommend , fund_recommend
        sex: gender, //เพศ
        age, //อายุปัจจุบัน
        retriment_age, // defualt 60
        death_age, // defualt 85
        outcome, //รายจ่ายปัจจุบัน
        inflationrate, //อัตราเงินเฟ้อ
        retryment_sh:
          outcome * 0.7 * (1 + inflationrate) ** (retriment_age - age), //เงินบำนาญขั้นต่ำที่ควรมี   =  outcome * 0.7 ((1+(inflation))**(retriment_age - Age))
        retryment_rq, //เงินบำนาญที่ต้องการรมี  ระบุเอง
        risk_form_id: risk_data.risk_form_id, //not edit  default จาก response.risk_form_id
        risk_assessed: risk_data.risk_assessed, //not edit  default จาก response.risk_assessed
        risk_accepted, //ความเสี่ยงจากการลงทุนที่รับได้ default จาก response.risk_assessed   แต่เลือกเปลี่ยนเองได้
        need_insurance: need_insurance, //  0 - 100 need_insurance + need_mutualfund =  100
        need_mutualfund: 100 - need_insurance, //  0 - 100 need_insurance + need_mutualfund =  100
        time: Date.now(),
        calculateAT: new Date().toLocaleString(),
      };
      const response = await axios.post(url, info);
      if (!!response.data.ins_plans) {
        setAllPlan(response.data.ins_plans);
      }
    }
  };
  useEffect(() => {
    getResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const PlanDataTable = () => (
    <Table
      type="column"
      headStyle={{
        borderRadius: "20px 20px 0 0",
        backgroundColor: color.blue[200],
      }}
      rowHeadStyle={{
        color: "white",
        backgroundColor: color.blue[400],
      }}
      tableKey={["plan", "premium", "pay_period", "period"]}
      label={{
        plan: "",
        premium: "เบี้ยประกัน",
        pay_period: "ระยะเวลาจ่าย",
        period: "ช่วงอายุรับเงินบำนาญ",
      }}
      data={allPlan}
    />
  );
  const PlanButton = (props: {
    id: string;
    text: string;
    plan_order: number;
  }) => {
    const {id, text, plan_order} = props;
    return (
      <Button
        className={styles.planButton}
        style={
          plan !== id ? {backgroundColor: color.blue[100], color: "black"} : {}
        }
        onClick={() => {
          temp.insure_plan = allPlan[plan_order];
          setPlan(id);
        }}
      >
        {text}
      </Button>
    );
  };
  const SelectPlan = () => (
    <div>
      <Typography variant="body1" style={{margin: "10px 0 10px 0"}}>
        เลือกผลประโยชน์ความคุ้มครอง
      </Typography>
      <div className={styles.planButtonContainer}>
        <PlanButton id="1" text="AIA ANNUITY FIX" plan_order={0} />
        <PlanButton id="2" text="เมืองไทย 8560" plan_order={1} />
        <PlanButton id="3" text="เมืองไทย 9960" plan_order={2} />
      </div>
      <img
        src={require(`local/retirementPlan/${idtoplan[plan]}.png`).default}
        alt="plan"
        style={{width: "100%"}}
      />
    </div>
  );

  const handleClick = (page: string) => {
    if (!temp.insure_plan) {
      temp.insure_plan = allPlan[0];
    }
    setTemp({
      ...temp,
      need_insurance_money: formData.need_insurance_money,
    });
    setData({
      ...data,
      retirePlan: {
        ...temp,
        need_insurance_money: formData.need_insurance_money,
      },
    });
    setPage(page);
  };

  return (
    <Box marginTop="20px">
      <Typography variant="h1" color="primary">
        วางแผนเกษียณ
      </Typography>
      <MoneyInput
        value={formData.need_insurance_money}
        onChange={(need_insurance_money: number) => {
          setFormData({...formData, need_insurance_money});
        }}
      />
      <PlanDataTable />
      <SelectPlan />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexWrap: "wrap",
        }}
      >
        <Button onClick={() => handleClick("mutualFund")}>กองทุนรวม</Button>
        <Button onClick={() => handleClick("result")}>สรุปผล</Button>
      </div>
    </Box>
  );
}

export default Insurance;
