import {Grid, Typography, Box, GridSize} from "@material-ui/core";
import React from "react";

interface Props {
  label: string;
  input: React.ReactElement;
  unit?: string;
  inputSm: GridSize;
  labelSm: GridSize;
  inputMd?: GridSize;
  labelMd?: GridSize;
  labelAlign?: "left" | "right";
}
function FormItem(props: Props) {
  const {label, input, unit, inputSm, labelSm, labelMd, inputMd, labelAlign} =
    props;
  return (
    <>
      <Grid
        item
        xs={labelSm}
        md={labelMd ? labelMd : labelSm}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: labelAlign == "right" ? "flex-end" : "",
        }}
      >
        <Typography>{label}</Typography>
      </Grid>

      <Grid item xs={inputSm} md={inputMd ? inputMd : inputSm}>
        <Box display="flex" alignItems="center">
          {input}
          {unit && <Typography style={{marginLeft: "10px"}}>{unit}</Typography>}
        </Box>
      </Grid>
    </>
  );
}
export default FormItem;
