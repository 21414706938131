import React from "react";
import {Chart, PieSeries} from "@devexpress/dx-react-chart-material-ui";
import {Palette} from "@devexpress/dx-react-chart";
import {
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {BsCircleFill} from "react-icons/bs";
import color from "config/color";
import getTypeColor from "../utils/getTypeColor";
interface IProps {
  data: {name: string; percent: number}[];
  label?: boolean;
  name?: string;
}
const useStyles = makeStyles((theme: Theme) => ({
  name: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: "10px",
    borderRadius: "10px",
    backgroundColor: color.grey[100],
    zIndex: 5,
  },
}));
function FundChart(props: IProps) {
  const {data, label, name} = props;
  const styles = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const palette = data.map((data) => getTypeColor(data.name));

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: isSm ? "column" : "row",
      }}
    >
      <div style={{position: "relative"}}>
        {name && <Typography className={styles.name}>{name}</Typography>}
        <Chart data={data} width={isSm ? 170 : 200} height={isSm ? 170 : 200}>
          <Palette scheme={palette} />
          <PieSeries valueField="percent" argumentField="name" />
        </Chart>
      </div>
      {label && (
        <div>
          {data.map(({name, percent}, idx) => (
            <div style={{display: "flex", alignItems: "center"}} key={idx}>
              <BsCircleFill color={palette[idx]} />
              <Typography style={{margin: "0 10px 0 5px", flexGrow: 1}}>
                {name}
              </Typography>
              <Typography>{percent + "%"}</Typography>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default FundChart;
