import {Hidden, makeStyles, Radio, Theme, Typography} from "@material-ui/core";
import {Grid} from "@material-ui/core";
import React from "react";
interface Props {
  text: string;
  isPrepared: number;
  setPrepared: (isPrepared: number) => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  radio: {
    alignSelf: "center",
  },
  radioContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
function FormItem(props: Props) {
  const {text, isPrepared, setPrepared} = props;
  const styles = useStyles();
  return (
    <Grid container style={{margin: "10px 0 10px 0"}}>
      <Grid item md={8} xs={12}>
        <Typography variant="body1">{text}</Typography>
      </Grid>
      <Grid item md={2} xs={6}>
        <div className={styles.radioContainer}>
          <Radio
            color="primary"
            checked={isPrepared === 1}
            onClick={() => {
              setPrepared(1);
            }}
            className={styles.radio}
          />
          <Hidden mdUp>
            <Typography variant="body2">เตรียมพร้อมแล้ว</Typography>
          </Hidden>
        </div>
      </Grid>
      <Grid item md={2} xs={6}>
        <div className={styles.radioContainer}>
          <Radio
            color="primary"
            checked={isPrepared === 0}
            onClick={() => {
              setPrepared(0);
            }}
            className={styles.radio}
          />
          <Hidden mdUp>
            <Typography variant="body2">ยังไม่เตรียม</Typography>
          </Hidden>
        </div>
      </Grid>
    </Grid>
  );
}

export default FormItem;
