import FinanCheck from "components/pages/finanCheck";
import HealthCare from "components/pages/healthCare";
import Home from "components/pages/home";
import NotFound from "components/pages/notFound";
import RetirementPlan from "components/pages/retirementPlan";
import SumAssured from "components/pages/sumAssured";
import finanCheck from "./finanCheck";
import healthCare from "./healthCare";
import sumAssured from "./sumAssured";
import retirementPlan from "./retirementPlan";

export interface IPageRoutes {
  [key: string]: {
    component: () => JSX.Element;
    type: "form" | "result";
  };
}

const routes: {[key: string]: () => JSX.Element} = {
  home: Home,
  finanCheck: FinanCheck,
  healthCare: HealthCare,
  retirementPlan: RetirementPlan,
  sumAssured: SumAssured,
  default: NotFound,
};

export const pageRoutes: {[key: string]: IPageRoutes} = {
  finanCheck,
  healthCare,
  sumAssured,
  retirementPlan,
};

export default routes;
