import React, {useContext, useState} from "react";
import {dataContext, financheckContext} from "context/context";
import {IFinanPlan} from "interface/interface";
import {PageRoutes} from "hooks/useRouter";

const FinanCheck = () => {
  const {data} = useContext(dataContext);
  let tempData;
  if (data.finanCheck) {
    tempData = data.finanCheck;
  } else {
    const allPlans = [
      "หลักประกันรายได้ครอบครัว",
      "ความคุ้มครองสุขภาพ",
      "ความคุ้มครองโรคร้ายแรง",
      "คุ้มครองอุบัติเหตุ",
      "เตรียมเงินเพื่อใช้หลังเกษียณ",
      "เงินออมเพื่อการศึกษาบุตร",
    ];
    const makePlans: IFinanPlan[] = [];
    allPlans.forEach((plan) => {
      makePlans.push({
        plan,
        check: false,
        useMoney: 0,
        prepareMoney: 0,
      });
    });
    const selectPlans: number[] = [];
    tempData = {
      isPrepared: [-1, -1, -1, -1, -1, -1],
      plans: makePlans,
      numplans: 6,
      selectPlans,
    };
  }
  const [temp, setTemp] = useState(tempData);

  return (
    <financheckContext.Provider value={{temp, setTemp}}>
      <PageRoutes />
    </financheckContext.Provider>
  );
};

export default FinanCheck;
