import axios from "axios";
import {dataContext, retireplanContext} from "context/context";
import useRouter from "hooks/useRouter";
import {IAssetClass} from "interface/interface";
import {useContext, useEffect, useState} from "react";
import useAnalytic from "./useAnalytic";

const useCustomMutualFund = () => {
  const {setPage} = useRouter();
  const {temp, setTemp} = useContext(retireplanContext);
  const {data, setData} = useContext(dataContext);
  const {fund_port} = temp;
  const sampleDict: {[key: string]: string} = {};
  const [assetClassDict, setAssetClassDict] = useState(sampleDict);
  const EmptyAssetArray: IAssetClass[] = [];
  const EmptyNumberArray: number[] = [];
  const [assetList, setAssetList] = useState(EmptyAssetArray);
  const [formData, setFormData] = useState({
    need_mutualfund_money: temp.need_mutualfund_money,
    selectedAssetList: !!fund_port ? fund_port.lists : [{f: "", w: 100, a: []}],
    percentList: !!fund_port
      ? fund_port.lists.map((list: IAssetClass) => {
          return list.w;
        })
      : EmptyNumberArray,
    port_id: fund_port.port_id.toString(),
  });

  // Fetch Data

  const getResult = async () => {
    let device_id = localStorage.getItem("device_id");
    const url = "https://fhc.insuretechthailand.com/ins/fund/data";
    const info = {
      device_id: device_id, // device_id ของ local
      cal_type: "assetclass_list", // function ที่เรียกใช้
    };
    const url2 = "https://fhc.insuretechthailand.com/ins/fund/list";
    const {risk_data} = temp;
    const info2 = {
      device_id: device_id, // device_id ของ local
      cal_type: "get_funds", // function ที่เรียกใช้
      ...risk_data,
    };
    const response = await axios.post(url, info);
    const response2 = await axios.post(url2, info2);
    if (!!response.data.data) {
      const sampleDict: {[key: string]: string} = {};
      response.data.data.forEach((element: {[key: string]: string}) => {
        const id = Object.keys(element)[0];
        sampleDict[id] = element[id];
      });
      setAssetClassDict(sampleDict);
    }
    if (response2.data.data) {
      setAssetList(response2.data.data);
    }
  };

  useEffect(() => {
    getResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let fundOption: {value: string; text: string}[] = [];
  const fundAssetDict: {[key: string]: {[key: number]: number}[]} = {};
  assetList.forEach((asset) => {
    fundOption.push({
      value: asset.f,
      text: asset.f,
    });
    fundAssetDict[asset.f] = asset.a;
  });

  // Percents
  const percents: {[key: number]: number} = {};
  formData.selectedAssetList.forEach((asset: IAssetClass) => {
    //If fund list didn't have same fund as recomend
    if (!fundAssetDict[asset.f]) {
      fundOption.push({
        value: asset.f,
        text: asset.f,
      });
      fundAssetDict[asset.f] = asset.a;
    }
    //Calculate percent and weight
    asset.a.forEach((percent) => {
      const aType: number = parseFloat(Object.keys(percent)[0]);
      const aPercent = percent[aType];
      if (!percents[aType]) {
        percents[aType] = 0;
      }
      percents[aType] += aPercent * asset.w;
    });
  });
  const percentList: {name: string; percent: number}[] = [];
  for (const [key, value] of Object.entries(percents)) {
    percentList.push({
      name: assetClassDict[key],
      percent: parseFloat((value / 100).toFixed(2)),
    });
  }

  // selected Fund
  const selectedFundData = formData.selectedAssetList.map(
    (port: IAssetClass, index: number) => ({
      name: port.f,
      key: index,
      onNameChange: (value: string) => {
        formData.selectedAssetList[index].f = value;
        formData.selectedAssetList[index].a = fundAssetDict[value];
        setFormData({
          ...formData,
          selectedAssetList: formData.selectedAssetList,
          port_id: "0",
        });
      },
      percent: formData.percentList[index],
      onPercentChange: (value: number) => {
        const percent = Math.max(Math.min(value, 100), 0);
        formData.percentList[index] = percent;
        formData.selectedAssetList[index].w = percent;
        setFormData({
          ...formData,
          selectedAssetList: formData.selectedAssetList,
          percentList: formData.percentList,
          port_id: "0",
        });
      },
      type:
        port.a.length == 1 ? assetClassDict[Object.keys(port.a[0])[0]] : "รวม",

      options: fundOption,
    })
  );

  // Add Fund
  const addFund = () => {
    const tempFundList = formData.selectedAssetList;
    tempFundList.push({
      f: "",
      w: 0,
      a: [],
    });
    formData.percentList.push(0);
    setFormData({
      ...formData,
      selectedAssetList: tempFundList,
      percentList: formData.percentList,
      port_id: "0",
    });
  };

  // MoneyInput
  const onMoneyChange = (need_mutualfund_money: number) => {
    setFormData({...formData, need_mutualfund_money});
  };

  const moneyInputProps = {
    onChange: onMoneyChange,
    value: formData.need_mutualfund_money,
  };

  // Analyze Port

  const analyze = useAnalytic(formData, temp);

  // Change Page
  const changePage = (page: string) => {
    const port = temp.fund_port;
    port.port_id = parseFloat(formData.port_id);
    if (port.port_id == 0) {
      port.name = "custom";
      port.lists = formData.selectedAssetList;
      port.stat = analyze.analytic;
    }
    setTemp({...temp, fund_port: port});
    setData({...data, retirePlan: {...temp, fund_port: port}});
    setPage(page);
  };

  return {
    addFund,
    moneyInputProps,
    selectedFundData,
    analyze,
    setPage: changePage,
    percentList,
  };
};

export default useCustomMutualFund;
