import {Box, makeStyles, Theme, Grid, Typography} from "@material-ui/core";
import Button from "components/common/Button";
import FormContainer from "components/common/FormContainer";
import React from "react";
import MoneyInput from "../components/MoneyInput";
import AnalyzePortTable from "./AnalyzePortTable";
import Fund from "./Fund";
import FundChart from "../components/FundChart";
import useDialog from "hooks/useDialog";
import Investment from "../Investment";
import useCustomMutualFund from "./hooks/useCustomMutualFund";
import AddFundButton from "./AddFundButton";

const useStyles = makeStyles((theme: Theme) => ({
  customFund: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
    },
  },
}));

function CustomMutualFund() {
  const styles = useStyles();
  const {Dialog, openDialog} = useDialog(Investment);
  const {
    addFund,
    moneyInputProps,
    selectedFundData,
    analyze,
    setPage,
    percentList,
  } = useCustomMutualFund();

  const selectedFund = selectedFundData.map((data) => <Fund {...data} />);

  return (
    <Box marginTop="20px">
      <Typography variant="h1" color="primary">
        วางแผนเกษียณ
      </Typography>
      <MoneyInput {...moneyInputProps} />
      <Grid container className={styles.customFund}>
        <Grid item xs={12} lg={6}>
          <FormContainer
            name="สัดส่วนการลงทุน"
            contentStyle={{padding: "10px 5px 10px 5px"}}
          >
            {selectedFund}
            <AddFundButton addFund={addFund} />
          </FormContainer>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              margin: "5px 0 20px 0",
            }}
          >
            <Button onClick={analyze.analyzePort}>
              วิเคราะห์พอร์ตการลงทุน
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} lg={6}>
          <FundChart data={percentList} label />
        </Grid>
      </Grid>
      <AnalyzePortTable data={analyze.analyzedData} />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <Button style={{margin: "auto"}} onClick={openDialog}>
          มูลค่าการลงทุนรายปี
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexWrap: "wrap",
        }}
      >
        <Button onClick={() => setPage("insurance")}>ประกันชีวิต</Button>
        <Button onClick={() => setPage("result")}>สรุปผล</Button>
      </div>
      <Dialog />
    </Box>
  );
}

export default CustomMutualFund;
