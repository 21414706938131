import React, {useContext, useEffect, useState} from "react";
import Result1 from "./result1";
import Result2 from "./result2";
import axios from "axios";
import {v4} from "uuid";
import {dataContext} from "context/context";
import {CircularProgress} from "@material-ui/core";

function Result() {
  const [step, setStep] = useState(1);
  const {data} = useContext(dataContext);
  const [planList, setPlanList] = useState(null);
  const healthCareData = data.healthCare;
  let device_id = localStorage.getItem("device_id");
  if (device_id === null) {
    device_id = v4();
    localStorage.setItem("device_id", device_id);
  }
  const getResult = async () => {
    const url = "https://fhc.insuretechthailand.com/ins/healthcare/cal";
    if (!!healthCareData) {
      const {age, gender, hospital, disease} = healthCareData!;
      const info = {
        cal_type: "longtermhealthcare",
        device_id,
        // sex: sumAssuredData.gender ? "female" : "male",
        age: age,
        sex: gender ? "female" : "male",
        hospital,
        disease,
        time: Date.now(),
        calculateAT: new Date().toLocaleString(),
      };
      const response = await axios.post(url, info);
      response.data.data[0].disease = disease;
      response.data.data[1].disease = disease;
      setPlanList(response.data);
    }
  };
  useEffect(() => {
    getResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (planList == null)
    return <CircularProgress style={{marginLeft: "45%", marginTop: "40vh"}} />;
  if (step == 1) {
    return <Result1 setStep={setStep} planList={planList!} />;
  } else {
    return <Result2 setStep={setStep} planList={planList!} />;
  }
}
export default Result;
