import {Box, Grid, makeStyles, Theme, Typography} from "@material-ui/core";
import React from "react";
import Card from "./component/Card";
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: "900px",
    margin: "auto",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
}));
function Home() {
  const styles = useStyles();
  return (
    <>
      <Box className={styles.container}>
        <Typography
          variant="h1"
          style={{marginTop: "10px", marginBottom: "20px"}}
        >
          เลือกการวางแผนที่เหมาะสมกับคุณ
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card id={1} />
              </Grid>
              <Grid item xs={6}>
                <Card id={2} />
              </Grid>
              <Grid item xs={6}>
                <Card id={3} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card id={4} />
              </Grid>
              <Grid item xs={6}>
                <Card id={5} />
              </Grid>
              <Grid item xs={6}>
                <Card id={6} />
              </Grid>
              <Grid item xs={6}>
                <Card id={7} />
              </Grid>
              <Grid item xs={6}>
                <Card id={8} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
export default Home;
