const RpqQuestion = [
  {
    question: "ปัจจุบันคุณอายุ",
    choices: ["มากกว่า 55 ปี", "45-55 ปี", "35-44 ปี", "น้อยกว่า 35 ปี"],
  },
  {
    question:
      "ปัจจุบันคุณมีภาระทางการเงินและค่าใช้จ่ายประจำ เช่น ค่าผ่อนบ้าน รถ ค่าใช้จ่ายส่วนตัว และค่าเลี้ยงดูครอบครัว",
    choices: [
      "มากกว่าร้อยละ 75 ของรายได้ทั้งหมด",
      "ระหว่างร้อยละ 50 ถึงร้อยละ 75 ของรายได้ทั้งหมด",
      "ระหว่างร้อยละ 25 ถึงร้อยละ 50 ของรายได้ทั้งหมด",
      "น้อยกว่าร้อยละ 25 ของรายได้ทั้งหมด",
    ],
  },
  {
    question: "คุณมีสถานภาพทางการเงินในปัจจุบันอย่างไร",
    choices: [
      "มีทรัพย์สินน้อยกว่าหนี้สิน",
      "มีทรัพย์สินเท่ากับหนี้สิน",
      "มีทรัพย์สินมากกว่าหนี้สิน",
      "มีความมั่นใจว่ามีเงินออมหรือเงินลงทุนเพียงพอสำหรับการใช้ชีวิตหลังเกษียณอายุแล้ว",
    ],
  },
  {
    question:
      "คุณเคยมีประสบการณ์ หรือมีความรู้ในการลงทุนในทรัพย์สินกลุ่มใดต่อไปนี้บ้าง (เลือกได้มากกว่า 1 ข้อ)",
    multiple: true,
    choices: [
      "เงินฝากธนาคาร",
      "พันธบัตรรัฐบาล หรือกองทุนรวมพันธบัตรรัฐบาล",
      "หุ้นกู้ หรือกองทุนรวมตราสารหนี้",
      "หุ้นสามัญ หรือกองทุนรวมหุ้น หรือสินทรัพย์อื่นที่มีความเสี่ยงสูง",
    ],
  },
  {
    question: "ระยะเวลาที่คุณคาดว่าจะไม่มีความจำเป็นต้องใช้เงินลงทุนนี้",
    choices: ["ไม่เกิน 1 ปี", "1 ถึง 3 ปี", "3 ถึง 5 ปี", "มากกว่า 5 ปี"],
  },
  {
    question: "วัตถุประสงค์หลักในการลงทุนของคุณ คือ",
    choices: [
      "เน้นเงินต้นต้องปลอดภัยและได้รับผลตอบแทนสม่ำเสมอแต่ต่ำได้",
      "เน้นโอกาสได้รับผลตอบแทนที่สม่ำเสมอ แต่อาจเสี่ยงที่จะสูญเสียเงินต้นได้บ้าง",
      "เน้นโอกาสได้รับผลตอบแทนที่สูงขึ้น แต่อาจเสี่ยงที่จะสูญเสียเงินต้นได้มากขึ้น",
      "เน้นผลตอบแทนสูงสุดในระยะยาว แต่อาจเสี่ยงที่จะสูญเงินต้นส่วนใหญ่ได้",
    ],
  },
  {
    question:
      "เมื่อพิจารณารูปแสดงตัวอย่างผลตอบแทนของกลุ่มการลงทุนที่อาจเกิดขึ้นด้านล่าง คุณเต็มใจที่จะลงทุนในกลุ่มการลงทุนใด",
    choices: [
      "กลุ่มการลงทุนที่ 1 มีโอกาสได้รับผลตอบแทน 2.5% โดยไม่ขาดทุนเลย",
      "กลุ่มการลงทุนที่ 2 มีโอกาสได้รับผลตอบแทนสูงสุด 7% แต่อาจมีผลขาดทุนได้ถึง 1%",
      "กลุ่มการลงทุนที่ 3 มีโอกาสได้รับผลตอบแทนสูงสุด 15% แต่อาจมีผลขาดทุนได้ถึง 5%",
      "กลุ่มการลงทุนที่ 4 มีโอกาสได้รับผลตอบแทนสูงสุด 25% แต่อาจมีผลขาดทุนได้ถึง 15%",
    ],
  },
  {
    question:
      "ถ้าคุณเลือกลงทุนในทรัพย์สินที่มีโอกาสได้รับผลตอบแทนมาก แต่มีโอกาสขาดทุนสูงด้วยเช่นกัน คุณจะรู้สึกอย่างไร",
    choices: [
      "กังวลและตื่นตระหนกกลัวขาดทุน",
      "ไม่สบายใจแต่พอเข้าใจได้บ้าง",
      "เข้าใจและรับความผันผวนได้ในระดับหนึ่ง",
      "ไม่กังวลกับโอกาสขาดทุนสูง และหวังกับผลตอบแทนที่อาจจะได้รับสูงขึ้น",
    ],
  },
  {
    question:
      "คุณจะรู้สึกกังวล/รับไม่ได้ เมื่อมูลค่าเงินลงทุนของคุณมีการปรับตัวลดลงในสัดส่วนเท่าใด",
    choices: [
      "5% หรือน้อยกว่า",
      "มากกว่า 5%-10%",
      "มากกว่า 10%-20%",
      "มากกว่า 20% ขึ้นไป",
    ],
  },
  {
    question:
      "หากปีที่แล้วคุณลงทุนไป 100,000 บาท ปีนี้คุณพบว่ามูลค่าเงินลงทุนลดลงเหลือ 85,000 บาท คุณจะทำอย่างไร",
    choices: [
      "ตกใจ และต้องการขายการลงทุนที่เหลือทิ้ง",
      "กังวลใจ และจะปรับเปลี่ยนการลงทุนบางส่วนไปในทรัพย์สินที่เสี่ยงน้อยลง",
      "อดทนถือต่อไปได้ และรอผลตอบแทนปรับตัวกลับมา",
      "ยังมั่นใจ เพราะเข้าใจว่าต้องลงทุนระยะยาว และจะเพิ่มเงินลงทุนในแบบเดิมเพื่อเฉลี่ยต้นทุน",
    ],
  },
];

export default RpqQuestion;
