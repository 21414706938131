import {Dialog, makeStyles, Theme, Typography} from "@material-ui/core";
import React, {useContext, useState} from "react";
import {financheckContext} from "context/context";
import {IFinanPlan} from "interface/interface";
import DialogItem from "./DialogItem";
import color from "config/color";
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "500px",
    backgroundColor: color.grey[100],
  },
  tableHead: {
    backgroundColor: color.blue[400],
    padding: "10px",
    color: "white",
    fontSize: "1.5rem",
    textAlign: "center",
  },
}));
const SelectDialog = (props: {open: boolean; onClose: () => void}) => {
  const {temp, setTemp} = useContext(financheckContext);
  const {onClose, open} = props;
  const styles = useStyles();

  const addPlan = (id: number) => {
    const plans = temp.plans;
    plans[id].check = !plans[id].check;
    if (id >= temp.numplans && !plans[id].check) {
      plans.splice(id, 1);
    }
    setTemp({...temp, plans});
  };

  const [other, setOther] = useState("");
  const otherHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOther(event.target.value);
  };

  const otherClickHandler = () => {
    const plans = temp.plans;
    plans.push({
      plan: other,
      check: true,
      useMoney: 0,
      prepareMoney: 0,
    });
    setOther("");
    setTemp({...temp, plans});
  };

  const choiceList = temp.plans.map((item: IFinanPlan, id: number) => {
    return (
      <DialogItem
        key={id}
        id={id}
        plan={item.plan}
        checked={item.check}
        onClick={addPlan}
      />
    );
  });

  return (
    <>
      <Dialog classes={{paper: styles.container}} onClose={onClose} open={open}>
        <Typography className={styles.tableHead}>โปรดเพิ่มแผน</Typography>
        <div>
          {choiceList}
          <DialogItem
            id={-1}
            plan={other}
            checked={false}
            onClick={otherClickHandler}
            type="other"
            otherHandleChange={otherHandleChange}
          />
        </div>
      </Dialog>
    </>
  );
};
export default SelectDialog;
