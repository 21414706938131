import {Grid, Hidden, makeStyles, Theme, Typography} from "@material-ui/core";
import Button from "components/common/Button";
import color from "config/color";
import {financheckContext} from "context/context";
import useRouter from "hooks/useRouter";
import React, {useContext, useEffect, useState} from "react";
import Form0Item from "./component/Form0Item";
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: "900px",
    margin: "auto",
  },
  title: {
    marginTop: "20px",
    color: "white",
    background: color.blue[400],
    padding: "10px",
    textAlign: "center",
    borderRadius: "20px",
  },
  resultContainer: {
    display: "flex",
    marginTop: "50px",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
  },
}));

const texts = [
  "1. เงินสำหรับครอบครัวที่สามารถดำรงชีพอยู่ได้อีก 5 ปีรวมถึงภาระหนี้สิน",
  "2. ค่ารักษาพยาบาล และทุนสำรองสำหรับกรณีเกิดโรคร้ายแรง",
  "3. ค่าใช้จ่ายหลังเกษียณ",
  "4. เงินทุนการศึกษาบุตรเพื่อใช้ในอนาคต",
  "5. มรดกสำหรับครอบครัว",
  "6. การวางแผนภาษี",
];

function Form0() {
  const styles = useStyles();
  const {setPage} = useRouter();
  const {temp, setTemp} = useContext(financheckContext);

  const [isPrepared, setAllPrepared] = useState(temp.isPrepared);
  const setPrepared = (index: number) => {
    return (value: number) => {
      isPrepared[index] = value;
      setAllPrepared([...isPrepared]);
      setResult(calculateResult());
    };
  };

  const [result, setResult] = useState("");
  const calculateResult = (): string => {
    let sum = 0;
    for (let i of isPrepared) {
      if (i === -1) return "";
      sum += i;
    }
    if (sum <= 2)
      return "ท่านมีความเสี่ยงส่วนบุคคลสูง รีบติดต่อรับฟังคำปรึกษาโดยด่วน";
    if (sum <= 4)
      return "ท่านมีความเสี่ยงส่วนบุคคลปานกลาง ควรระมัดระวังในความเสี่ยงที่ยังไม่เตรียมพร้อม";
    return "ท่านมีความเสี่ยงส่วนบุคคลน้อยมาก สามารถรับคำปรึกษาเพื่อวางแผนจัดการความเสี่ยงให้ครบทุกด้าน";
  };

  const linkPlan = [0, 1, 2, 3, 4, 5];
  const submit = () => {
    isPrepared.forEach((val: number, index: number) => {
      if (val == 0) {
        temp.plans[linkPlan[index]].check = true;
      }
    });
    setTemp({...temp, isPrepared});
    setPage("form1");
  };

  useEffect(() => {
    setPrepared(0)(isPrepared[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const TableHead = () => (
    <Grid container style={{marginTop: "30px", marginBottom: "40px"}}>
      <Grid item xs={12} md={8}>
        <Typography variant="body1">
          ท่านได้มีการวางแผนรับมือกับความเสี่ยงเหล่านี้ด้านใดบ้าง
        </Typography>
      </Grid>
      <Hidden smDown>
        <Grid item xs={2}>
          <Typography
            variant="body1"
            style={{
              textAlign: "center",
            }}
          >
            เตรียมพร้อมแล้ว
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            variant="body1"
            style={{
              textAlign: "center",
            }}
          >
            ยังไม่เตรียม
          </Typography>
        </Grid>
      </Hidden>
    </Grid>
  );

  const questionList = texts.map((text: string, index: number) => (
    <Form0Item
      text={text}
      isPrepared={isPrepared[index]}
      setPrepared={setPrepared(index)}
      key={index}
    />
  ));

  const Result = () => (
    <div className={styles.resultContainer}>
      <Typography variant="body1">{result}</Typography>
      <Button className={styles.button} disabled={!result} onClick={submit}>
        แสดงผล
      </Button>
    </div>
  );

  return (
    <div className={styles.container}>
      <Typography variant="h2" className={styles.title}>
        แบบประเมินการเตรียมพร้อมทางการเงินสำหรับความเสี่ยงส่วนบุคคล
      </Typography>
      <TableHead />
      {questionList}
      <Result />
    </div>
  );
}

export default Form0;
