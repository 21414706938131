import React from "react";
import Row from "./Row";
interface IProps {
  tableKey: string[]; // Keys of each data object.
  label: {[key: string]: string}; // Lables of the keys.
  data: {[key: string]: string | number}[]; // Data to be rendered.
  type?: "row" | "column"; // Determine that one data object will be display in one row or one column.
  headStyle?: React.CSSProperties; // Style of the table head.
  rowHeadStyle?: React.CSSProperties; // Style of the row head
  highlight?: string[][];
  // The cells of the table you want to highlight.
  // Each array contains the key of data object at the same index you want to highlight.
}

function Table(props: IProps) {
  const {tableKey, label, data, type, headStyle, rowHeadStyle, highlight} =
    props;

  // Column type display
  if (type === "column") {
    return (
      <div>
        {tableKey.map((key, idx) => {
          const rowData: (string | number)[] = [label[key]];
          for (const row of data) {
            rowData.push(row[key]);
          }
          const rowHighlight: boolean[] = [false];
          if (highlight) {
            for (const row of highlight) {
              rowHighlight.push(row.includes(key));
            }
          }
          return (
            <Row
              data={rowData}
              highlight={rowHighlight}
              key={idx}
              head={idx === 0}
              headStyle={headStyle}
              rowHeadStyle={rowHeadStyle}
              firstRow={idx === 1}
              lastRow={idx === tableKey.length - 1}
            />
          );
        })}
      </div>
    );
  }

  // Row type display
  const getDataArray = (dataObject: {[key: string]: string | number}) => {
    const data: (string | number)[] = [];
    for (const key of tableKey) {
      data.push(dataObject[key]);
    }
    return data;
  };
  return (
    <div>
      <Row data={getDataArray(label)} head headStyle={headStyle} />
      {data.map((rowData, idx) => {
        const rowHighlight: boolean[] = [];
        if (highlight) {
          for (const key of tableKey) {
            rowHighlight.push(highlight[idx].includes(key));
          }
        }
        return (
          <Row
            data={getDataArray(rowData)}
            key={idx}
            rowHeadStyle={rowHeadStyle}
            firstRow={idx === 0}
            lastRow={idx === data.length - 1}
            highlight={rowHighlight}
          />
        );
      })}
    </div>
  );
}

export default Table;
