import {makeStyles, Theme, Typography} from "@material-ui/core";
import color from "config/color";
import React from "react";

interface IProps {
  name: string;
  contentStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

function FormContainer(props: IProps) {
  const styles = useStyles();
  const {children, name, contentStyle, style} = props;

  return (
    <div className={styles.formContainer} style={style}>
      <Typography className={styles.formHead}>{name}</Typography>
      <div className={styles.formContent} style={contentStyle}>
        {children}
      </div>
    </div>
  );
}
export default FormContainer;

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    maxWidth: "900px",
    margin: "auto",
    marginTop: "20px",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  formHead: {
    backgroundColor: color.blue[400],
    padding: "10px",
    color: "white",
    fontSize: "1.5rem",
    textAlign: "center",
    borderRadius: "20px 20px 0 0 ",
  },
  formContent: {
    backgroundColor: color.grey[100],
    padding: "20px",
    borderRadius: "0 0 20px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
}));
