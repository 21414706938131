import {Grid, makeStyles, Theme, Typography} from "@material-ui/core";
import FormContainer from "components/common/FormContainer";
import GenderInput from "components/common/GenderInput";
import NumberInput from "components/common/NumberInput";
import {dataContext} from "context/context";
import {Formik, Form} from "formik";
import useRouter from "hooks/useRouter";
import {IHealthCare} from "interface/interface";
import React, {useContext} from "react";
import FormItem from "./component/FormItem";
import SelectInput from "components/common/SelectInput";
import Button from "components/common/Button";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: "900px",
    margin: "auto",
    marginTop: "20px",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
}));

function HealthCareForm() {
  const {setPage} = useRouter();
  const {data, setData} = useContext(dataContext);

  const initialValues = data.healthCare || {
    gender: "male",
    age: 30,
    hospital: "Premium",
    disease: 0,
  };

  const handleSubmit = (value: IHealthCare) => {
    setData({...data, healthCare: value});
    setPage("result");
  };

  const styles = useStyles();
  return (
    <div className={styles.container}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <Typography variant="h1" color="primary">
            ประกันสุขภาพระยะยาว
          </Typography>
          <FormContainer name="กรอกข้อมูลสำคัญ">
            <Grid container spacing={2}>
              <FormItem
                label="เพศ"
                labelMd={1}
                inputMd={4}
                labelSm={2}
                inputSm={10}
                input={<GenderInput name="gender" />}
              />
              <FormItem
                label="อายุ"
                labelMd={1}
                inputMd={6}
                labelSm={2}
                inputSm={10}
                unit="ปี"
                labelAlign="right"
                input={<NumberInput name="age" />}
              />
              <FormItem
                label="ประเภทโรงพยาบาลที่ใช้บริการ"
                labelSm={6}
                inputSm={6}
                input={
                  <SelectInput
                    name="hospital"
                    options={[
                      {value: "Premium", text: "Premium"},
                      {value: "เอกชน", text: "โรงพยาบาลเอกชน"},
                      {value: "รัฐบาล", text: "โรงพยาบาลรัฐบาล"},
                    ]}
                  />
                }
              />
              <FormItem
                label="ความคุ้มครองโรคร้ายแรงที่ต้องการเตรียม"
                labelSm={6}
                inputSm={6}
                unit="บาท"
                input={<NumberInput name="disease" />}
              />
            </Grid>
          </FormContainer>
          <Button style={{float: "right"}} type="submit">
            ต่อไป
          </Button>
        </Form>
      </Formik>
    </div>
  );
}
export default HealthCareForm;
