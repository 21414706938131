import background from "local/healthCare.png";
import {Hidden} from "@material-ui/core";
import {PageRoutes} from "hooks/useRouter";

function HealthCare() {
  return (
    <>
      <Hidden smDown>
        <img
          src={background}
          alt=""
          style={{
            bottom: 0,
            right: 0,
            height: "min(70vh, 70vw)",
            zIndex: -1,
            position: "fixed",
          }}
        />
      </Hidden>

      <PageRoutes />
    </>
  );
}
export default HealthCare;
