import {Grid, makeStyles, Theme, Typography} from "@material-ui/core";
import Button from "components/common/Button";
import FormContainer from "components/common/FormContainer";
import color from "config/color";
import React from "react";

interface IProps {
  closeDialog: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  cell: {
    border: "1px solid white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}));

function Investment(props: IProps) {
  const {closeDialog} = props;
  const styles = useStyles();
  const labels = [
    "ปี",
    "อายุ",
    "อัตราผลตอบแทนที่คาดหวัง",
    "มูลค่าการลงทุนที่คาดหวัง",
  ];

  const Cell = (props: {text: string | number; style: React.CSSProperties}) => (
    <Grid item xs={3}>
      <div className={styles.cell} style={props.style}>
        <Typography style={{textAlign: "center"}}>{props.text}</Typography>
      </div>
    </Grid>
  );

  const Row = (props: {
    data: (string | number)[];
    style: React.CSSProperties;
  }) => (
    <Grid container>
      {props.data.map((value) => (
        <Cell text={value} style={props.style} />
      ))}
    </Grid>
  );

  return (
    <FormContainer
      name="มูลค่าการลงทุนที่คาดหวัง"
      style={{width: "95%"}}
      contentStyle={{backgroundColor: "white"}}
    >
      <div style={{margin: "10px 0 10px 0"}}>
        <Row
          data={labels}
          style={{color: "white", backgroundColor: color.blue[400]}}
        />
        <Row data={[1, 1, 1, 1]} style={{backgroundColor: color.grey[100]}} />
      </div>
      <Button style={{display: "block", margin: "auto"}} onClick={closeDialog}>
        กลับหน้าแสดงผล
      </Button>
    </FormContainer>
  );
}

export default Investment;
