import Form from "components/pages/sumAssured/form";
import Result from "components/pages/sumAssured/result";
import {IPageRoutes} from "routes";

const pageRoutes: IPageRoutes = {
  default: {
    type: "form",
    component: Form,
  },
  form: {
    type: "form",
    component: Form,
  },
  result: {
    component: Result,
    type: "result",
  },
};
export default pageRoutes;
