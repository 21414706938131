import {Box, Hidden, makeStyles, Theme, Typography} from "@material-ui/core";
import Button from "components/common/Button";
import color from "config/color";
import React, {useState} from "react";
import RadarChart from "react-svg-radar-chart";
import PlanButton from "./component/PlanButton";
const useStyles = makeStyles((theme: Theme) => ({
  scale: {
    fill: color.grey[100],
    stroke: color.grey[200],
  },
  axis: {
    stroke: "black",
    strokeWidth: ".8",
  },
  caption: {
    backgroundColor: "white",
    margin: "10px",
    borderRadius: "20px",
    padding: "10px",
    color: color.blue[400],
  },
  container: {
    maxWidth: "900px",
    margin: "auto",
    marginTop: "20px",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  content: {
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      flexDirection: "column-reverse",
    },
  },
  roomCost: {
    position: "absolute",
    textAlign: "center",
    width: "100%",
    top: 0,
  },
  seriousIllnessCost: {
    position: "absolute",
    left: "5%",
    bottom: "15%",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      left: "0",
    },
  },
  packageCost: {
    position: "absolute",
    right: "5%",
    bottom: "15%",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      right: "0",
    },
  },
}));

const captions = {
  room: "ค่าห้องพัก",
  disease: "ความคุ้มครองโรคร้ายแรง",
  health: "ค่ารักษาพยาบาลแบบเหมาจ่าย",
};

interface PlanList {
  scale: {
    room: number;
    health: number;
    disease: number;
  };
  data: {
    plan: string;
    room: number;
    health: number;
    disease: number;
    premium: number;
    remark?: string;
  }[];
}
interface Props {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  planList: PlanList;
}

function Result2(props: Props) {
  const styles = useStyles();
  const {planList, setStep} = props;
  const options = {
    scales: 1,
    scaleProps: () => ({className: styles.scale, fill: "none"}),
    axisProps: () => ({className: styles.axis}),
    captions: false,
  };
  const dataList = [
    {
      room: planList.data[0].room / planList.scale.room,
      disease: planList.data[0].disease / planList.scale.disease,
      health: planList.data[0].health / planList.scale.health,
    },
    {
      room: planList.data[1].room / planList.scale.room,
      disease: planList.data[1].disease / planList.scale.disease,
      health: planList.data[1].health / planList.scale.health,
    },
  ];
  const [data, setData] = useState([
    {
      data: dataList[0],
      meta: {color: `${color.blue[200]}88`},
    },
    {
      data: dataList[1],
      meta: {color: `${color.grey[200]}88`},
    },
  ]);
  return (
    <div className={styles.container}>
      <Typography variant="h1" color="primary">
        ประกันสุขภาพระยะยาว
      </Typography>
      <div className={styles.content}>
        <PlanButton
          plan={[planList.data[0].plan, planList.data[1].plan]}
          setData={setData}
          data={data}
          dataList={dataList}
        />
        <Box display="flex" justifyContent="center" flexGrow="1">
          <Box
            position="relative"
            display="inline-block"
            justifyContent="center"
          >
            <Hidden xsDown>
              <RadarChart
                captions={captions}
                data={data}
                size={500}
                options={options}
              />
            </Hidden>
            <Hidden smUp>
              <RadarChart
                captions={captions}
                data={data}
                size={300}
                options={options}
              />
            </Hidden>

            <Typography variant="h3" className={styles.roomCost}>
              ค่าห้องพัก
            </Typography>
            <Typography variant="h3" className={styles.seriousIllnessCost}>
              ค่ารักษาพยาบาล
              <br />
              แบบเหมาจ่าย
            </Typography>
            <Typography variant="h3" className={styles.packageCost}>
              ค่ารักษา
              <br />
              โรคร้ายแรง
            </Typography>
          </Box>
        </Box>
      </div>

      <Box display="flex" justifyContent="flex-end" marginTop="20px">
        <Typography variant="body1">{planList.data[0].remark}</Typography>
        <Typography variant="body1">{planList.data[1].remark}</Typography>
      </Box>

      <Button
        style={{
          float: "right",
          marginTop: "15px",
        }}
        onClick={() => {
          setStep(1);
        }}
      >
        ตาราง
      </Button>
    </div>
  );
}
export default Result2;
